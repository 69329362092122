import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { isCurrencyNumber } from "../../../../utils/isCurrency";

const SectionChartProfit = ({ data }) => {
  const { state } = useContext(GlobalContext);
  const { darkMode } = state;

  const [options, setOptions] = useState({
    chart: {
      type: "column",
      backgroundColor: "transparent",
      height: 350,
      scrollablePlotArea: {
        minWidth: 800, // Minimum width to enable scrolling
        scrollPositionX: 0, // Start at the beginning of the scroll
      },
    },

    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      title: {
        text: "",
      },
      scrollbar: {
        enabled: true,
      },
      labels: {
        style: {
          fontSize: "14px",
          color: darkMode ? "#fff" : "#000",
        },
      },
    },

    plotOptions: {
      column: {
        grouping: true,
        borderWidth: 0,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },

      labels: {
        style: {
          fontSize: "10px",
          color: darkMode ? "#fff" : "#000",
        },
      },
      opposite: true,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        // Custom formatter for tooltip
        const points = this.points.map(
          (point) =>
            `<span style="color:${point.color}">● ${
              point.series.name
            }</span>: <b>Rp.${isCurrencyNumber(point.y)}</b>`
        );
        return `<span>${this.x}</span><br/>${points.join("<br/>")}`;
      },
    },
    series: [],
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",

      backgroundColor: "transparent",
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      xAxis: {
        ...options.xAxis,
        categories: data?.map((item) =>
          moment(item?.dateCreated).format("YYYY")
        ),
        labels: {
          ...options.xAxis.labels,
          style: {
            ...options.xAxis.labels.style,
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      yAxis: {
        ...options.yAxis,
        labels: {
          ...options.yAxis.labels,
          style: {
            ...options.yAxis.labels.style,
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Total Revenue",
          data: data?.map((item) => item?.totalRevenueLatestPeriod),
          color: "#4489FF",
          tooltip: {
            valueSuffix: " IDR",
          },
        },
        {
          name: "Operating Income",
          data: data?.map((item) => item?.operatingIncomeLatestPeriod),
          color: "#4ED0E0",
          tooltip: {
            valueSuffix: " IDR",
          },
        },
        {
          name: "Pretax Income",
          data: data?.map((item) => item?.profitBeforeTaxLatestPeriod),
          color: "#F57E16",
          tooltip: {
            valueSuffix: " IDR",
          },
        },
        {
          name: "Net Income",
          data: data?.map((item) => item?.salesLatestPeriod),
          color: "#B288FF",
          tooltip: {
            valueSuffix: " IDR",
          },
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, darkMode]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SectionChartProfit;
