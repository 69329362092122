import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Card, CardBody } from "reactstrap";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { isCurrencyWithOutRpFormat } from "../../../utils/isCurrency";

const ChartMarket = ({
  data,
  value,
  name,
  title,
  t,
  tooltip,
  onClick = () => {},
}) => {
  console.log(data);
  const { state } = useContext(GlobalContext);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 200,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        pointWidth: 15,
      },
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      chart: {
        ...options.chart,
        backgroundColor: state.darkMode ? "#212531" : "#ffffff",
      },
      xAxis: {
        ...options.xAxis,
        categories: data.map((item) => item.code),
        labels: {
          style: {
            color: state.darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      series: [
        {
          name: "",
          data: data.length > 0 ? data?.map((item) => item[value]) : [],
        },
      ],
      tooltip: tooltip ? tooltip : options.tooltip,
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return isCurrencyWithOutRpFormat(this.y);
            },
          },
        },
      },
    });
    // eslint-disable-next-line
  }, [data, tooltip, state?.darkMode]);
  return (
    <Card>
      <div className="d-flex justify-content-between flex-row mt-3">
        <div className="d-flex justify-content-start">
          <div className="bg-brown p-1 ">
            <span className="text-dark fw-bold font-description">
              {title?.toUpperCase()}
            </span>
          </div>
        </div>
        <div
          className="d-flex flex-row gap-1 align-items-center me-1"
          onClick={onClick}
          style={{
            cursor: "pointer",
          }}
        >
          <span className="font-small text-primary">
            {t("market.view_all")}
          </span>
          <IoIosArrowDropright size={16} className="text-primary" />
        </div>
      </div>
      <CardBody>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className="d-flex justify-content-center">
          <span className="font-label ">{name}</span>
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartMarket;
