import React from "react";
import { Card, CardBody } from "reactstrap";
import { UnderConstruction } from "../../../../assets/img";

const SectionForecast = () => {
  return (
    <Card className="mt-3 ">
      <CardBody>
        <div className="mb-2">
          <span className="font-extra-large fw-bold">Forecast</span>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <img
            src={UnderConstruction}
            style={{ width: "300px", height: "300px" }}
            alt=""
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionForecast;
