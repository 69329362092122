import moment from "moment";
import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Card, CardBody, Table } from "reactstrap";
import { NoData } from "../../../../assets/img";
import CEmpty from "../../../../components/CEmpty";
import { isCurrencyWithOutRp } from "../../../../utils/isCurrency";
import IsMobile from "../../../../utils/IsMobile";
import SectionChartCashFlow from "./SectionChartCashFlow";

const SectionCashFlow = ({
  cashFlow,
  fieldCashFlow,
  onShowChildCashFlow,
  t,
  darkMode,
  objectCashFlow,
}) => {
  const mobileView = IsMobile();
  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <div className="d-flex flex-column">
          <span className="font-extra-large fw-bold">
            {t("detail.cash_flow")}
          </span>
          <span className="text-secondary font-title">
            {t("detail.standalone_figures")}
            <span
              className="text-primary"
              style={{ fontSize: mobileView ? "14px" : "16px" }}
            >
              {" "}
              / {t("detail.view_consolidated")}
            </span>
          </span>
        </div>
        <SectionChartCashFlow data={cashFlow} />
        {cashFlow?.length > 0 ? (
          <div className="table-container">
            <Table className={`detail-table ${darkMode && "table-dark"}`}>
              <thead>
                <tr className="quarter-tr">
                  {fieldCashFlow.map((date, index) => (
                    <th
                      key={index}
                      style={{
                        fontWeight: "500",
                        fontSize: mobileView ? "10px" : "12px",
                      }}
                    >
                      <p className="font-description p-0 m-0">
                        {date?.quarter}
                      </p>
                      <span className="font-description">
                        {date?.dateCreated?.includes("#")
                          ? ""
                          : moment(date?.dateCreated).format("MMM YYYY")}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {objectCashFlow.map((itemObj, indexObj) => {
                  if (itemObj?.isShow === true) {
                    return (
                      <tr key={indexObj} className={"quarter-tr"}>
                        <td
                          onClick={() =>
                            itemObj?.haveChild &&
                            onShowChildCashFlow(itemObj?.slug)
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight: itemObj?.isBold ? "bold" : "normal",
                            fontSize: mobileView ? "10px" : "12px",
                            backgroundColor: itemObj?.isBold
                              ? darkMode
                                ? " #2d2d2d"
                                : "white"
                              : itemObj.childOf
                              ? darkMode
                                ? " #2d2d2d"
                                : "#f6f6f6"
                              : darkMode
                              ? "#14161f"
                              : "#FFF",
                          }}
                        >
                          {t("cashFlow." + itemObj?.label)}{" "}
                          {itemObj?.haveChild ? (
                            itemObj?.isOpen ? (
                              <FaMinus size={8} color="#665FFD" />
                            ) : (
                              <FaPlus size={8} color="#665FFD" />
                            )
                          ) : (
                            ""
                          )}
                        </td>
                        {cashFlow.map((itemValue, indexValue) => (
                          <td
                            key={indexValue}
                            style={{
                              backgroundColor: itemObj?.isBold
                                ? darkMode
                                  ? " #2d2d2d"
                                  : "white"
                                : itemObj.childOf
                                ? darkMode
                                  ? " #2d2d2d"
                                  : "#f6f6f6"
                                : darkMode
                                ? "#14161f"
                                : "#FFF",
                            }}
                          >
                            <span
                              style={{
                                fontSize: mobileView ? "10px" : "12px",
                                fontWeight: itemObj?.isBold ? "bold" : "normal",
                              }}
                            >
                              {itemObj?.label?.includes("%")
                                ? itemValue[itemObj?.slug]?.toFixed(2)
                                : isCurrencyWithOutRp(
                                    itemValue[itemObj?.slug]
                                  )}{" "}
                              {itemObj?.label?.includes("%") ? "%" : ""}
                            </span>
                          </td>
                        ))}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={250} />}
            title={t("detail.no_data")}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SectionCashFlow;
