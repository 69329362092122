import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import QuerySettingApplication from "../graphQL/Query/QuerySettingApplication";
import StockScreenerRange from "../graphQL/Query/StockScreenerRange";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    darkMode: localStorage.getItem("darkMode")
      ? JSON.parse(localStorage.getItem("darkMode"))
      : window.matchMedia("(prefers-color-scheme: dark)").matches
      ? true
      : false,
    sectionActive: "summary",
    company: null,
  });
  const [parameters, setParameters] = useState(
    secureLocalStorage.getItem("parameters")
      ? JSON.parse(secureLocalStorage.getItem("parameters"))
      : null
  );

  const [stockScreener, setStockScreener] = useState(
    secureLocalStorage.getItem("stockScreener")
      ? JSON.parse(secureLocalStorage.getItem("stockScreener"))
      : null
  );
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(
    secureLocalStorage.getItem("settings")
      ? JSON.parse(secureLocalStorage.getItem("settings"))
      : null
  );

  const fetchParameters = async () => {
    const response = await axios.get(
      `https://storage.sambalado.xyz/redbel-history/parameters.json`
    );

    if (response?.data?.length > 0) {
      secureLocalStorage.setItem(
        "parameters",
        JSON.stringify(response?.data?.filter((item) => item?.origin?.key))
      );

      setParameters(response?.data?.filter((item) => item?.origin?.key));
    }
  };

  const [fetchSettings] = useLazyQuery(QuerySettingApplication, {
    onCompleted: (data) => {
      secureLocalStorage.setItem("settings", JSON.stringify(data.settings));
      setSettings(data.settings);
    },
  });

  const [fetchStockScreener] = useLazyQuery(StockScreenerRange, {
    onCompleted: (data) => {
      secureLocalStorage.setItem(
        "stockScreener",
        JSON.stringify(data?.stockScreenerRange)
      );
      setStockScreener(data?.stockScreenerRange);
    },
  });

  useEffect(() => {
    fetchParameters();
    fetchStockScreener();
    if (settings === null) {
      fetchSettings();
    }
    // eslint-disable-next-line
  }, []);

  // get update section active
  const updateSection = (section) =>
    setState((prevState) => ({ ...prevState, sectionActive: section }));

  const toggleTheme = () => {
    localStorage.setItem("darkMode", JSON.stringify(!state.darkMode));
    setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  const updateCompanyName = (value) =>
    setState((prevState) => ({ ...prevState, company: value }));

  return (
    <GlobalContext.Provider
      value={{
        state,
        parameters,
        settings,
        loading,
        stockScreener,
        setLoading,
        updateSection,
        toggleTheme,
        updateCompanyName,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
