import React from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Badge, Table } from "reactstrap";
import IsMobile from "../utils/IsMobile";
import {
  isCurrencyWithOutDecimal,
  isCurrencyWithOutRpAndWithOutFormat,
  isCurrencyWithOutRpFormat,
} from "../utils/isCurrency";
import { isFloat } from "../utils/isFloat";
import CAvatar from "./CAvatar";
import CPagination from "./CPagination";

const CTableSector = ({
  t,
  state,
  field,
  data,
  pagination,
  countData,
  handlePageClick,
  onClickNavigate,
  handleSortBy,
  sortBy,
}) => {
  const mobileView = IsMobile();

  return (
    <div>
      <div className="table-container">
        <Table
          className={`detail-table ${
            state?.darkMode ? "table-dark" : "table-white"
          }`}
        >
          <thead>
            <tr className="peer-tr">
              {field?.map((item, index) => (
                <th
                  className={
                    index === 0
                      ? "peer-first-col font-label"
                      : index === 1
                      ? "peer-second-col font-label"
                      : "font-label "
                  }
                  id={item?.slug}
                  key={index}
                >
                  <div className="d-flex flex-row gap-2 align-items-center">
                    {!["s_no", "name"].includes(item.slug) && (
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                          onClick={() =>
                            handleSortBy({
                              key: item?.value,
                              sort: "DESC",
                            })
                          }
                        >
                          <IoMdArrowDropup
                            size={14}
                            color={
                              sortBy?.key === item?.value &&
                              sortBy?.sort === "DESC"
                                ? "#0078ff"
                                : "#d9d9d9"
                            }
                          />
                        </div>
                        <div
                          className=" d-flex align-items-center justify-content-center"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                          onClick={() =>
                            handleSortBy({
                              key: item.value,
                              sort: "ASC",
                            })
                          }
                        >
                          <IoMdArrowDropdown
                            size={14}
                            color={
                              sortBy?.key === item?.value &&
                              sortBy?.sort === "ASC"
                                ? "#0078ff"
                                : "#d9d9d9"
                            }
                          />
                        </div>
                      </div>
                    )}
                    {t(`detail.${item.slug}`)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} className="peer-tr">
                <td className="peer-first-col font-label">
                  {index + 1 + (pagination?.page - 1) * pagination?.limit}
                </td>
                <td
                  className="peer-second-col font-label fw-semibold"
                  onClick={() => onClickNavigate(item)}
                  style={{
                    cursor: "pointer",
                    maxWidth: mobileView ? "120px" : "500px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {!mobileView ? (
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <CAvatar
                        src={item?.logo}
                        name={item?.code}
                        alt="Logo"
                        size={"30px"}
                        objectFit="cover"
                      />
                      <Badge color="info" pill>
                        {item?.code}
                      </Badge>
                      {item?.name?.toUpperCase()}
                    </div>
                  ) : (
                    item?.name?.toUpperCase()
                  )}
                </td>
                <td className="font-label">
                  {isCurrencyWithOutDecimal(
                    item?.stockSummaries?.length > 0
                      ? isFloat(item?.stockSummaries[0]?.price) || 0
                      : 0
                  )}
                </td>
                <td className="font-label">
                  {isCurrencyWithOutRpAndWithOutFormat(
                    item?.stockSummaries?.length > 0
                      ? item?.stockSummaries[0]?.priceToEarning
                      : 0
                  )}
                </td>
                <td className="font-label">
                  Rp{" "}
                  {isCurrencyWithOutRpFormat(
                    item?.stockSummaries?.length > 0
                      ? item?.stockSummaries[0]?.marketCapitalization
                      : 0
                  )}
                </td>
                <td className="font-label">
                  {item?.stockSummaries?.length > 0
                    ? isFloat(item?.stockSummaries[0]?.dividendYield || 0) +
                      " %"
                    : 0 + " %"}
                </td>
                <td className="font-label">
                  Rp{" "}
                  {isCurrencyWithOutRpFormat(
                    item?.stockSummaries?.length > 0
                      ? item?.stockSummaries[0]?.netProfitLatestQuarter
                      : 0
                  )}
                </td>
                <td className="font-label">
                  {item?.stockSummaries?.length > 0
                    ? isFloat(
                        item?.stockSummaries[0]?.yoyQuarterlyProfitGrowth || 0
                      ) + " %"
                    : 0 + " %"}
                </td>
                <td className="font-label">
                  Rp{" "}
                  {isCurrencyWithOutRpFormat(
                    item?.stockSummaries?.length > 0
                      ? item?.stockSummaries[0]?.salesLatestQuarter
                      : 0
                  )}
                </td>
                <td className="font-label">
                  {item?.stockSummaries?.length > 0
                    ? isFloat(
                        item?.stockSummaries[0]?.yoyQuarterlySalesGrowth || 0
                      ) + " %"
                    : 0 + " %"}
                </td>
                <td className="font-label">
                  {item?.stockSummaries?.length > 0
                    ? isFloat(
                        item?.stockSummaries[0]?.returnOnCapitalEmployed || 0
                      ) + " %"
                    : 0 + " %"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div>
        <CPagination
          page={pagination?.page}
          totalItems={countData}
          itemsPerPage={pagination?.limit}
          onPageChange={handlePageClick}
        />
      </div>
    </div>
  );
};

export default CTableSector;
