import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import CBreadcrumb from "../../components/CBreadcrumb";
import CLoading from "../../components/CLoading";
import CTableSector from "../../components/CTableSector";
import { GlobalContext } from "../../contexts/GlobalContext";
import QueryCompareSector from "../../graphQL/Query/QueryCompareSector";
import fieldPeerComparison from "../company/component/field/fieldPeerComparison.json";

const CompareSector = () => {
  const { t } = useTranslation("global");

  const [sector] = useState(useLocation()?.state?.sector || "");
  const [industry] = useState(useLocation()?.state?.industry || "");
  const [sectors, setSectors] = useState([]);
  const [field] = useState(fieldPeerComparison);
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState({});
  const [currentPath] = useState(`/sector/compare/${sector}`);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [countData, setCountData] = useState(0);

  const breadcrumbItems = [
    { label: sector ? "Sector" : "Industry", path: "/screen" },
    {
      label: sector ? sector : industry,
      path: sector
        ? `/sector/compare/${sector}`
        : `/sector/compare/${industry}`,
    },
  ];

  const [fetchSector, { loading }] = useLazyQuery(QueryCompareSector, {
    onCompleted: (data_) => {
      setCountData(data_?.companyPagination?.count);
      setSectors(data_?.companyPagination?.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchSector({
      variables: {
        sector: sector,
        industry: industry,
        page: pagination.page,
        limit: pagination.limit,
      },
    });
    // eslint-disable-next-line
  }, []);

  const handlePageClick = (page) => {
    setPagination({ ...pagination, page: page });
    fetchSector({
      variables: {
        sector: sector,
        page: page,
        limit: pagination?.limit,
        sortBy: [sortBy],
      },
    });
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    setPagination({ ...pagination, page: 1 });
    fetchSector({
      variables: {
        sector: sector,
        page: 1,
        limit: pagination?.limit,
        sortBy: [value],
      },
    });
  };
  return (
    <div>
      {loading && <CLoading />}
      <CBreadcrumb items={breadcrumbItems} currentPath={currentPath} />
      <Card>
        <CardBody>
          <div>
            <p className="font-large fw-semibold">
              SECTORS {sector.toLocaleUpperCase()}
            </p>
          </div>
          <CTableSector
            t={t}
            state={state}
            field={field}
            data={sectors}
            pagination={pagination}
            countData={countData}
            onClickNavigate={(item) => navigate(`/company/${item?.code}`)}
            handlePageClick={handlePageClick}
            handleSortBy={handleSortBy}
            sortBy={sortBy}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default CompareSector;
