import React from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import CPagination from "../../../components/CPagination";
import IsMobile from "../../../utils/IsMobile";
import {
  isCurrencyWithOutRpAndWithOutFormat,
  isCurrencyWithOutRpFormat,
} from "../../../utils/isCurrency";
import { isFloat } from "../../../utils/isFloat";

const StockTable = ({
  state,
  t,
  fieldTable,
  data,
  pagination,
  countData,
  handlePageClick,
  handleSortBy,
  sortBy,
}) => {
  const mobileView = IsMobile();
  const navigate = useNavigate();

  function checkString(value) {
    if (typeof value === "string") {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <div className="table-container">
        <Table
          className={` detail-table ${
            state?.darkMode ? "table-dark" : "table-white"
          }`}
        >
          <thead>
            <tr className="peer-tr">
              <th className={"peer-first-col font-description"}>
                <div className="d-flex  align-items-center">
                  {t(`detail.s_no`)}
                </div>
              </th>

              {fieldTable?.map((item, index) => (
                <th
                  className={
                    index === 0
                      ? "peer-second-col font-description"
                      : "font-description "
                  }
                  id={item?.key}
                  key={index}
                >
                  <div className="d-flex flex-row gap-2 align-items-center">
                    {![
                      "companyName",
                      "companyCode",
                      "primarySector",
                      "subSector",
                      "industry",
                      "subIndustry",
                    ].includes(item?.value) && (
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                          onClick={() =>
                            handleSortBy({
                              key: item?.key,
                              sort: "DESC",
                            })
                          }
                        >
                          <IoMdArrowDropup
                            size={14}
                            color={
                              sortBy?.key === item?.key &&
                              sortBy?.sort === "DESC"
                                ? "#0078ff"
                                : "#d9d9d9"
                            }
                          />
                        </div>
                        <div
                          className=" d-flex align-items-center justify-content-center"
                          style={{
                            width: "14px",
                            height: "14px",
                          }}
                          onClick={() =>
                            handleSortBy({
                              key: item.key,
                              sort: "ASC",
                            })
                          }
                        >
                          <IoMdArrowDropdown
                            size={14}
                            color={
                              sortBy?.key === item?.key &&
                              sortBy?.sort === "ASC"
                                ? "#0078ff"
                                : "#d9d9d9"
                            }
                          />
                        </div>
                      </div>
                    )}
                    {t(`summary.${item.value}`)}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} className="peer-tr">
                <td className="peer-first-col font-label">
                  {index + 1 + (pagination?.page - 1) * pagination?.limit}
                </td>
                {fieldTable?.map((field, index) => (
                  <td
                    className={`${
                      index === 0
                        ? "peer-second-col font-description"
                        : "font-description "
                    }`}
                    onClick={() => {
                      if (field?.key === "name") {
                        navigate(`/company/${item.code}`);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      maxWidth: mobileView ? "120px" : "500px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {[
                      "name",
                      "code",
                      "primarySector",
                      "subSector",
                      "industry",
                      "subIndustry",
                    ].includes(field?.key)
                      ? item[field?.key]
                      : item?.stockSummaries?.length > 0
                      ? checkString(item.stockSummaries[0][field?.value])
                        ? item.stockSummaries[0][field?.value]
                        : isFloat(item?.stockSummaries[0]?.[field?.value])
                        ? isCurrencyWithOutRpFormat(
                            item?.stockSummaries[0]?.[field?.value] || 0
                          )
                        : isCurrencyWithOutRpAndWithOutFormat(
                            item?.stockSummaries[0]?.[field?.value] || 0
                          )
                      : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div>
        <CPagination
          page={pagination?.page}
          totalItems={countData}
          itemsPerPage={pagination?.limit}
          onPageChange={handlePageClick}
        />
      </div>
    </div>
  );
};

export default StockTable;
