import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const CPagination = ({ totalItems, page = 1, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(page);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  // Fungsi untuk merender nomor halaman
  const renderPageNumbers = () => {
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > 5) {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // Menambahkan halaman pertama jika tidak ada dalam rentang
    if (startPage > 1) {
      pages.push(
        <PaginationItem key={1} onClick={() => handlePageClick(1)}>
          <PaginationLink className="shadow-none">1</PaginationLink>
        </PaginationItem>
      );
      if (startPage > 2)
        pages.push(<PaginationItem key="dot-start">...</PaginationItem>);
    }

    // Menambahkan halaman tengah
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink
            onClick={() => handlePageClick(i)}
            className="shadow-none"
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Menambahkan halaman terakhir jika tidak ada dalam rentang
    if (endPage < totalPages) {
      if (endPage < totalPages - 1)
        pages.push(<PaginationItem key="dot-end">...</PaginationItem>);
      pages.push(
        <PaginationItem
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
        >
          <PaginationLink className="shadow-none">{totalPages}</PaginationLink>
        </PaginationItem>
      );
    }

    return pages;
  };

  return (
    <div className={`d-flex justify-content-center`}>
      <Pagination>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            href="#"
            onClick={() => handlePageClick(currentPage - 1)}
          />
        </PaginationItem>

        {renderPageNumbers()}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            href="#"
            onClick={() => handlePageClick(currentPage + 1)}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default CPagination;
