import React, { useContext } from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { Badge } from "reactstrap";
import { NoImage } from "../assets/img";
import { GlobalContext } from "../contexts/GlobalContext";
import IsMobile from "../utils/IsMobile";
import { isCurrency } from "../utils/isCurrency";

const CListSearch = ({
  from = "home",
  companies,
  widthMobile,
  widthDesktop,
}) => {
  const { state } = useContext(GlobalContext);
  const mobileView = IsMobile();

  const isMinus = (value) => {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div
      className={`position-absolute p-2 ${
        state?.darkMode ? "bg-secondary text-light" : "bg-light"
      } mt-1 rounded shadow-sm z-1`}
      style={{
        maxHeight: "300px",
        overflowY: "auto",
        width: mobileView ? widthMobile : widthDesktop,
      }}
    >
      {companies?.map((item) => (
        <div
          className="p-2 card-search d-flex flex-row justify-content-between border-bottom"
          style={{
            fontSize: mobileView ? "10px" : "14px",
            cursor: "pointer",
          }}
          onClick={() => (window.location.href = `/company/${item?.code}`)}
        >
          <div className="d-flex flex-row gap-2" style={{ width: "70%" }}>
            {from !== "navbar" && (
              <div>
                <img
                  src={item?.logo || NoImage}
                  alt={item?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = NoImage;
                  }}
                  style={{
                    objectFit: "contain",
                    width: mobileView ? "20px" : "40px",
                    height: mobileView ? "20px" : "40px",
                  }}
                />
              </div>
            )}
            {from !== "navbar" && (
              <div className="d-flex flex-column">
                <span className="font-small">Stock Rank</span>
                <Badge color="info" pill>
                  {item?.stock_rank}
                </Badge>
              </div>
            )}
            <div className="d-flex flex-column">
              <span
                className={`fw-bold ${
                  mobileView ? "font-small" : "font-description"
                }  `}
              >
                {item?.name}
              </span>
              <span className="fw-bold font-small">{item?.code}</span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <span
              className={`fw-bold ${
                mobileView ? "font-small" : "font-description"
              }  `}
            >
              {isCurrency(item?.price ? item?.price : 0)}
            </span>
            <div>
              {isMinus(item?.change ? item?.change : 0) ? (
                <FaArrowAltCircleDown size={10} color="red" />
              ) : (
                <FaArrowAltCircleUp size={10} color="green" />
              )}{" "}
              <span
                className={`${mobileView ? "font-small" : "font-description"} ${
                  isMinus(item?.change ? item?.change : 0)
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {item?.change ? item?.change : 0}
                {"%"}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CListSearch;
