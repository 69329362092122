import { gql } from "@apollo/client";

const QueryCompareSector = gql`
  query CompareSector(
    $sector: String
    $industry: String
    $page: Int
    $limit: Int
    $sortBy: [OrderingStockScreenerParam]
  ) {
    companyPagination(
      sector: $sector
      industry: $industry
      page: $page
      limit: $limit
      sortBy: $sortBy
    ) {
      page
      limit
      count
      data {
        ... on CompanySchema {
          id
          code
          name
          logo
          primarySector
          industry
          stockSummaries {
            price
            priceToEarning
            marketCapitalization
            dividendYield
            netProfitLatestQuarter
            yoyQuarterlyProfitGrowth
            salesLatestQuarter
            yoyQuarterlySalesGrowth
            returnOnCapitalEmployed
          }
        }
      }
    }
  }
`;

export default QueryCompareSector;
