import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import HC_More from "highcharts/highcharts-more";

HC_More(Highcharts);

const SectionChartCapitalStruktur = ({ series }) => {
  const [options, setOptions] = useState({
    chart: {
      type: "waterfall",
      height: 200,
      width: 250,
      backgroundColor: "transparent",
    },
    title: {
      text: null,
    },

    xAxis: {
      visible: false,
      lineWidth: 1,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
    },

    yAxis: {
      visible: false,
      labels: {
        enabled: false,
      },
      gridLineWidth: 1,
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25A0</span> {point.name}: <b>{point.y}</b>',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },

    series: [],
  });

  useEffect(() => {
    setOptions({
      ...options,
      series: series,
    });
    // eslint-disable-next-line
  }, [series]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SectionChartCapitalStruktur;
