const GenerateQuery = ({ sector, params, sortBy, limit, page, fields }) => {
  return `
    query StockScreener {
      stockScreener(
        sector: "${sector}"
        params: ${params}
        sortBy: ${sortBy}
        page: ${page}
        limit: ${limit}
      ) {
          page
          limit
          count
          data {
          ... on CompanySchema {
            name
            code
            primarySector
            subSector
            industry
            subIndustry
            stockSummaries {
              ${fields.join("\n")}
            }
          }
        }
      }
  }
  `;
};

export default GenerateQuery;
