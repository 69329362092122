import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { CiCircleRemove } from "react-icons/ci";
import { FaSave, FaSearch, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import CInput from "../../components/CInput";
import CLoading from "../../components/CLoading";
import MetaHelmet from "../../components/MetaHelmet";
import { GlobalContext } from "../../contexts/GlobalContext";
import UpdateProfile from "../../graphQL/Mutation/UpdateProfile";
import IsMobile from "../../utils/IsMobile";
import userRef from "../company/component/field/userRef.json";
import ratioJSOn from "./tabRatio.json";
const ManageRatio = () => {
  const { companyId } = useParams();
  const tabSection = useLocation()?.state?.section;
  const navigate = useNavigate();

  const { t } = useTranslation("global");
  const mobileView = IsMobile();
  const alert = useAlert();
  const { parameters, state } = useContext(GlobalContext);
  const [tabRatio] = useState(ratioJSOn);
  const [tabSelected, setTabSelected] = useState(tabSection || ratioJSOn[0]);
  const [parametersTemp, setParametersTemp] = useState(parameters);
  const [search, setSearch] = useState("");
  const [dashboard_arr, setDashboardArr] = useState(
    secureLocalStorage.getItem("dashboard_arr")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_arr"))
      : userRef
  );
  const [dashboard_obj, setDashboardObj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : []
  );
  const uniqueData = parametersTemp.filter(
    (value, index, self) =>
      index === self.findIndex((item) => item.value === value.value)
  );

  const [checkedAll, setCheckedAll] = useState(
    uniqueData?.length === dashboard_arr?.length ? true : false
  );

  const onSearch = (value) => {
    setSearch(value);
    const newParameters = parameters.filter((parameter) =>
      parameter.value_display_name.toLowerCase().includes(value.toLowerCase())
    );
    setParametersTemp(newParameters);
  };

  useEffect(() => {
    setParametersTemp(parameters);

    // eslint-disable-next-line
  }, [parameters]);

  const [updateRatio, { loading }] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: () => {
      secureLocalStorage.setItem(
        "dashboard_arr",
        JSON.stringify(dashboard_arr)
      );
      secureLocalStorage.setItem(
        "dashboard_obj",
        JSON.stringify(dashboard_obj)
      );
      alert.success("Update ratio successfully");
      setTimeout(() => {
        navigate("/company/" + companyId);
      }, 1000);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onCheckChecked = (item) => {
    if (dashboard_arr?.some((summary) => summary.value === item.value)) {
      return true;
    } else {
      return false;
    }
  };

  const onChangeChecked = (e, item) => {
    if (onCheckChecked(item)) {
      setDashboardArr(
        dashboard_arr.filter((summary) => summary.value !== item.value)
      );
      let newDashboard = {
        ...dashboard_obj,
      };

      delete newDashboard[item.section][item.value];

      setDashboardObj(newDashboard);
    } else {
      setDashboardArr([...dashboard_arr, item]);
      let newDashboard = {
        ...dashboard_obj,
      };
      newDashboard[item.section] = {
        ...newDashboard[item.section],
        [item.value]: true,
      };
      setDashboardObj(newDashboard);
    }
  };

  // const onSelectAll = (tabSelected, parametersTemp) => {
  //   let parameter = [...parametersTemp];
  //   let dashboardArr = [...dashboard_arr];
  //   let newDashboard = {
  //     ...dashboard_obj,
  //   };
  //   let parameterByTabSlug = parameter.filter(
  //     (item) => item.section === tabSelected.slug
  //   );

  //   let dashboardArrBySlug = dashboardArr.filter(
  //     (item) => item.section.toLowerCase() === tabSelected.slug.toLowerCase()
  //   );
  //   console.log(dashboardArr);
  //   console.log(dashboardArrBySlug, parameterByTabSlug.length);
  //   if (dashboardArrBySlug.length === parameterByTabSlug.length) {
  //     dashboardArr = dashboardArr.filter(
  //       (item) => item.section !== tabSelected.slug
  //     );
  //     delete newDashboard[tabSelected.slug];
  //     setDashboardObj(newDashboard);
  //     return;
  //   }

  //   parameterByTabSlug.forEach((item) => {
  //     if (!dashboardArr?.some((summary) => summary.value === item.value)) {
  //       dashboardArr.push(item);
  //       newDashboard[tabSelected.slug] = {
  //         ...newDashboard[tabSelected.slug],
  //         [item.value]: true,
  //       };
  //       setDashboardObj(newDashboard);
  //     }
  //   });
  //   setDashboardArr(dashboardArr);
  // };

  const onHandleSubmit = () => {
    updateRatio({
      variables: {
        dashboard: dashboard_obj,
      },
    });
  };

  const onClearAll = () => {
    setDashboardArr([]);
    setDashboardObj({});
  };

  const CardSelectedRatio = ({ section, dashboardUser }) => {
    const [viewAll, setViewAll] = useState([]);
    const dashboardBySection = section.reduce((acc, curr) => {
      const filtered = dashboardUser.filter(
        (item) => item?.section === curr.slug
      );
      if (filtered.length > 0) {
        acc.push({ ...curr, data: filtered });
      }
      return acc;
    }, []);

    const onOpenCard = (label) => {
      const newView = [...viewAll];

      if (newView?.some((item) => item === label)) {
        newView.splice(
          newView.findIndex((item) => item === label),
          1
        );
      } else {
        newView.push(label);
      }
      setViewAll(newView);
    };

    return (
      <Row>
        {dashboardBySection?.map((item_, index_) => (
          <Col sm="12" lg="6" md="12" className="mb-2">
            <Card
              className={`border border-1 shadow-sm ${
                viewAll?.filter((item) => item === item_?.label)?.length > 0 &&
                "h-100"
              } `}
            >
              <div key={item_?.slug} className=" rounded p-2 h-100">
                <div className="my-1 d-flex justify-content-between">
                  <span className="font-label fw-semibold ">
                    {item_?.label}
                  </span>
                  <div>
                    <Badge color="info" pill>
                      {item_?.data?.length || 0} Ratios
                    </Badge>
                  </div>
                </div>
                {viewAll?.filter((item) => item === item_?.label)?.length >
                  0 && (
                  <div className="d-flex flex-row gap-2 flex-wrap">
                    {item_?.data?.map((item, index) => (
                      <div
                        onClick={(e) => onChangeChecked(e, item)}
                        className={`px-2 py-1 d-flex  align-items-center gap-2 ${
                          state?.darkMode ? "bg-dark" : "bg-brown"
                        }  rounded`}
                        key={item.value}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <span className="font-label">
                          {item?.value_display_name || item?.valueDisplayName}
                        </span>
                        <CiCircleRemove size={14} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <CardFooter className="d-flex justify-content-center bg-light p-0 m-0 border-0">
                {viewAll?.filter((item) => item === item_?.label)?.length >
                0 ? (
                  <div
                    onClick={() => onOpenCard(item_?.label)}
                    className="px-2 py-1 d-flex  align-items-center gap-2"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span className="font-label text-primary fw-semibold">
                      Close
                    </span>
                    <BiUpArrowAlt size={14} color="#0078ff" />
                  </div>
                ) : (
                  <div
                    onClick={() => onOpenCard(item_?.label)}
                    className="px-2 py-1 d-flex  align-items-center gap-2 b"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span className="font-label text-primary fw-semibold">
                      View All
                    </span>
                    <BiDownArrowAlt size={14} color="#0078ff" />
                  </div>
                )}
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  const onSelectAll = (parametersTemp) => {
    let parameter = [...parametersTemp];
    let dashboardArr = [...dashboard_arr];
    let newDashboard = {
      ...dashboard_obj,
    };

    if (checkedAll) {
      setDashboardArr([]);
      setDashboardObj({});
      setCheckedAll(false);
      return;
    } else {
      parameter.forEach((item) => {
        if (!dashboardArr?.some((summary) => summary.value === item.value)) {
          dashboardArr.push(item);
          newDashboard[item.section] = {
            ...newDashboard[item.section],
            [item.value]: true,
          };
          setDashboardObj(newDashboard);
        }
      });
      setDashboardArr(dashboardArr);
      setCheckedAll(true);
    }
  };

  return (
    <div>
      <MetaHelmet title={"Manage Ratio"} description={"Select your ratios"} />
      <div>
        {loading && <CLoading />}
        <div>
          <Row>
            <Col sm="12" lg="12" md="12">
              <div>
                <p className="font-title fw-semibold">Your selected ratios</p>
              </div>
              <CardSelectedRatio
                section={ratioJSOn}
                dashboardUser={dashboard_arr}
              />

              <div className="mt-2 d-flex flex-row gap-3">
                <div className="d-flex flex-row gap-1">
                  <Button color="primary" size="sm" onClick={onHandleSubmit}>
                    <FaSave size={16} /> {t("manage_ratio.save_ratios")}
                  </Button>
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => navigate("/company/" + companyId)}
                  >
                    {t("manage_ratio.cancel")}
                  </Button>
                </div>
                {dashboard_arr?.length !== 0 && (
                  <div>
                    <Button
                      size="sm"
                      color="danger"
                      outline
                      onClick={onClearAll}
                    >
                      <FaTrash size={16} /> {t("manage_ratio.clear_all")}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Card className="mt-3">
            <CardBody>
              <Row
                className={`mt-sm-2 mt-2 mt-lg-3 py-2 ${
                  state?.darkMode ? "bg-darkMode" : "bg-brown"
                } d-flex align-items-end`}
              >
                <Col sm="12" lg="3" md="12">
                  <span className="font-label fw-bold mb-1">
                    {t("manage_ratio.filter_ratio")}
                  </span>
                  <CInput
                    placeholder={"Search ratio"}
                    heightInput={"40px"}
                    value={search}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                    leftIcon={<FaSearch size={14} />}
                  />
                </Col>

                <Col sm="12" lg="9" md="12">
                  <ButtonGroup
                    className="border flex-wrap mt-sm-2 mt-2 mt-lg-0"
                    size={mobileView ? "sm" : "lg"}
                  >
                    {tabRatio?.map((item) => (
                      <Button
                        onClick={() => setTabSelected(item)}
                        className={
                          tabSelected?.slug === item?.slug
                            ? "text-primary border-0 shadow-none"
                            : state?.darkMode
                            ? "text-white border-0 shadow-none"
                            : "text-secondary border-0 shadow-none"
                        }
                        style={{
                          backgroundColor:
                            tabSelected?.slug === item?.slug
                              ? state?.darkMode
                                ? "#2609b5"
                                : "#F3F4FF"
                              : state?.darkMode
                              ? "#606f7b"
                              : "#FFF",
                          fontSize: mobileView ? "12px" : "14px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
              <div className="my-2">
                <Col sm="4" lg="3" md="4" className="mt-3">
                  <FormGroup
                    check
                    inline
                    onClick={() => onSelectAll(parametersTemp)}
                  >
                    <Input
                      onClick={() => onSelectAll(parametersTemp)}
                      type="checkbox"
                      className="shadow-none"
                      style={{ cursor: "pointer" }}
                      checked={checkedAll}
                    />
                    <Label check style={{ cursor: "pointer" }}>
                      Select All
                    </Label>
                  </FormGroup>
                </Col>
              </div>
              <div className="d-flex flex-row flex-wrap ">
                {parametersTemp
                  ?.filter((item_) => item_?.section === tabSelected?.slug)
                  ?.map((item, index) => (
                    <Col key={index} sm="4" lg="3" md="4" className="mt-3">
                      <FormGroup
                        check
                        inline
                        onClick={(e) => onChangeChecked(e, item)}
                      >
                        <Input
                          onChange={(e) => {
                            onChangeChecked(e, item);
                          }}
                          type="checkbox"
                          className="shadow-none"
                          style={{ cursor: "pointer" }}
                          checked={onCheckChecked(item)}
                        />
                        <Label check style={{ cursor: "pointer" }}>
                          {item?.value_display_name}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ManageRatio;
