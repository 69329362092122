import { useLazyQuery } from "@apollo/client";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePresentationChartLine } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";

import axios from "axios";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "reactstrap";
import CLoading from "../../components/CLoading";
import CModalSide from "../../components/CModalSide";
import MetaHelmet from "../../components/MetaHelmet";
import { GlobalContext } from "../../contexts/GlobalContext";
import QuerySummary from "../../graphQL/Query/QuerySummary";
import { isCurrency } from "../../utils/isCurrency";
import { isFloat } from "../../utils/isFloat";
import isRandomColor from "../../utils/IsRandomColor";
import fieldAnnualIncome from "./component/field/fieldAnnualIncome.json";
import fieldBalanceSheets from "./component/field/fieldBalanceSheet.json";
import fieldCashFlows from "./component/field/fieldCashFlow.json";
import fieldCategoryChart from "./component/field/fieldCategoryChart.json";
import fieldPeer from "./component/field/fieldPeerComparison.json";
import fieldQuarterly from "./component/field/fieldQuarterly.json";
import fieldRatios from "./component/field/fieldRatio.json";
import SectionBalance from "./component/section/SectionBalance";
import SectionCashFlow from "./component/section/SectionCashFlow";
import SectionChart from "./component/section/SectionChart";
import SectionChartCapitalStruktur from "./component/section/SectionChartCapitalStruktur";
import SectionChartCompare from "./component/section/SectionChartCompare";
import SectionChartDivHistory from "./component/section/SectionChartDivHistory";
import SectionChartDivSummary from "./component/section/SectionChartDivSummary";
import SectionChartTrading from "./component/section/SectionChartTrading";
import SectionDocument from "./component/section/SectionDocument";
import SectionForecast from "./component/section/SectionForecast";
import SectionPeer from "./component/section/SectionPeer";
import SectionProfitLoss from "./component/section/SectionProfitLoss";
import SectionQuarter from "./component/section/SectionQuarter";
import SectionRatio from "./component/section/SectionRatio";
import SectionShareholding from "./component/section/SectionShareholding";
import SectionSummary from "./component/section/SectionSummary";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  TimeScale,
  ChartDataLabels,
  zoomPlugin,
  Filler,
  ArcElement
);

const Company = () => {
  const { companyId } = useParams();
  const { t, i18n } = useTranslation("global");
  const { state, updateCompanyName } = useContext(GlobalContext);
  const [companiesInfo, setCompaniesInfo] = useState({});
  const [aboutCompany, setAboutCompany] = useState("");
  const [keyPointsCompany, setKeyPointsCompany] = useState("");

  // variable capital structure
  const [seriesCapitalStructure, setSeriesCapitalStructure] = useState([]);
  // variable dividend summary
  const [seriesDividendSummary, setSeriesDividendSummary] = useState([]);
  const [dataDividendSummary, setDataDividendSummary] = useState(null);
  // variable dividend history
  const [seriesDividendHistory, setSeriesDividendHistory] = useState([]);
  const [categoriesDividendHistory, setCategoriesDividendHistory] = useState(
    []
  );
  // variable Chart
  const [tooltipChart, setToolTipChart] = useState(null);
  const [codesCompare, setCodesCompare] = useState([companyId]);
  const [isTradingView, setIsTradingView] = useState(false);
  const [isCompareActive, setIsCompareActive] = useState(false);
  const [companiesChart, setCompaniesChart] = useState([]);
  const [companiesCompare, setCompaniesCompare] = useState([]);
  const [categoryChart] = useState(fieldCategoryChart);
  const [categoryChartSelected, setCategoryChartSelected] = useState(
    fieldCategoryChart[0]
  );
  const [dateSelected, setDateSelected] = useState({
    label: "1Yr",
    dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
    averageBy: "monthly",
    dateTo: moment().format("YYYY-MM-DD"),
  });
  const [dateChart] = useState([
    {
      label: "1m",
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      averageBy: "daily",
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "6m",
      dateFrom: moment().subtract(6, "month").format("YYYY-MM-DD"),
      averageBy: "weekly",
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "1Yr",
      dateFrom: moment().subtract(1, "year").format("YYYY-MM-DD"),
      averageBy: "monthly",
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "3Yr",
      dateFrom: moment().subtract(3, "year").format("YYYY-MM-DD"),
      averageBy: "quarterly",
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "5Yr",
      dateFrom: moment().subtract(5, "year").format("YYYY-MM-DD"),
      averageBy: "halfYearly",
      dateTo: moment().format("YYYY-MM-DD"),
    },

    {
      label: "10Yr",
      dateFrom: moment().subtract(10, "year").format("YYYY-MM-DD"),
      averageBy: "yearly", // yearly
      dateTo: moment().format("YYYY-MM-DD"),
    },
    {
      label: "Max",
      dateFrom: null,
      averageBy: "yearly",
      interval: null,
      dateTo: null,
    },
  ]);

  const [categoryOptionChart, setCategoryOptionChart] = useState([]);
  const [seriesChart, setSeriesChart] = useState([]);
  const [yAxisChart, setYAxisChart] = useState([]);
  const [seriesTradingView, setSeriesTradingView] = useState([]);
  const [optionCompare, setOptionCompare] = useState(null);

  // variable sales growth, profit growth, price CAGR, ROE
  const [compoundSalesGrowth, setCompoundSalesGrowth] = useState(null);
  const [dataSalesGrowth, setDataSalesGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsSalesGrowth, setOptionsSalesGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [compoundProfitGrowth, setCompoundProfitGrowth] = useState(null);
  const [dataProfitGrowth, setDataProfitGrowth] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsProfitGrowth, setOptionsProfitGrowth] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [compoundstockPriceCagr, setCompoundStockPriceCagr] = useState(null);
  const [dataStockPriceCagr, setDataStockPriceCagr] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsStockPriceCagr, setOptionsStockPriceCagr] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  const [returnOnEquity, setReturnOnEquity] = useState(null);
  const [dataReturnOnEquity, setDataReturnOnEquity] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsReturnOnEquity, setOptionsReturnOnEquity] = useState({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {},
    scales: {
      y: {},
    },
  });

  // variable peerComparison
  const [tooltipOpenPeer, setTooltipOpenPeer] = useState({});
  const [fieldPeerComparison] = useState(fieldPeer);
  const [peerComparison, setPeerComparison] = useState([]);

  // variable   Quarter Result
  const [quarter, setQuarter] = useState([]);
  const [fieldQuarter, setFieldQuarter] = useState([]);
  const [objectQuarter, setObjectQuarter] = useState(fieldQuarterly);

  //Variable Profit Loss
  const [profitLoss, setProfitLoss] = useState([]);
  const [fieldProfitLoss, setFieldProfitLoss] = useState([]);
  const [objectProfitLoss, setObjectProfitLoss] = useState(fieldAnnualIncome);

  // variable Balance Sheet
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [fieldBalanceSheet, setFieldBalanceSheet] = useState([]);
  const [objectBalanceSheet, setObjectBalanceSheet] =
    useState(fieldBalanceSheets);

  // variable cash flow
  const [cashFlow, setCashFlow] = useState([]);
  const [fieldCashFlow, setFieldCashFlow] = useState([]);
  const [objectCashFlow, setObjectCashFlow] = useState(fieldCashFlows);

  // for ratio
  const [ratio, setRatio] = useState({});

  const [ratioData, setRatioData] = useState([]);
  const [fieldRatio, setFieldRatio] = useState([]);
  const [objectRatio] = useState(fieldRatios);

  // for shareholding pattern
  const [shareHoldingPattern, setShareHoldingPattern] = useState([]);
  const [dataSetShareHoldingPattern, setDataSetShareHoldingPattern] = useState({
    labels: [],
    datasets: [],
  });

  const [dataOptionsShareHoldingPattern, setDataOptionsShareHoldingPattern] =
    useState({
      responsive: true,
      interaction: {},
      plugins: {},
      scales: {},
    });

  // variable announcement
  const [announcements, setAnnouncements] = useState([]);
  const [annualReports, setAnnualReports] = useState([]);
  // modal
  const [isOpenMore, setIsOpenMore] = useState(false);
  const [isOpenCompare, setIsOpenCompre] = useState(false);
  const [isExpandedAbout, setIsExpandedAbout] = useState(false);

  // fetch detail, summary dan report
  const [fetchSummary, { loading }] = useLazyQuery(QuerySummary, {
    variables: {
      code: companyId,
    },
    onCompleted: async (data) => {
      await setCompaniesInfo(data?.company[0]);
      await updateCompanyName(data?.company[0]);
      await setPeerComparison(data?.company[0]?.peerComparison);
      await onCapitalStructure(data?.company[0]?.stockSummaries[0]);
      await onDividendSummary(data?.company[0]?.dividendSummary);
      await onDividendHistory(data?.company[0]?.dividendHistory);
      await onCompound(data?.company[0]);
      await onQuarterIncome(data?.company[0]?.quarterlyIncome);
      await onProfitLoss(data?.company[0]?.annualIncome);
      await onBalanceSheet(data?.company[0]?.balanceSheet);
      await onCashFlow(data?.company[0]?.cashFlow);
      await setRatio(
        data?.company[0].ratio.length > 0 ? data?.company[0].ratio[0] : {}
      );
      await setRatioData(data?.company[0].ratio);
      await onRatio(data?.company[0].ratio);
      await onShareHolding(data?.company[0].stockHolders);
      await setAnnouncements(data?.company[0].announcement);
      await onAnnualReport(data?.company[0].annualIncome);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  useEffect(() => {
    onTranslate();
    // eslint-disable-next-line
  }, [companiesInfo, i18n.language]);

  const GetTranslate = async (value) => {
    try {
      const response = await fetch(
        `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${
          i18n.language
        }&dt=t&q=${encodeURIComponent(value)}`
      );
      const result = await response.json();
      const combinedText = result[0].map((item) => item[0]).join("");
      return {
        text: combinedText,
        status: true,
      };
    } catch (error) {
      return {
        text: "",
        status: false,
      };
    }
  };

  const onTranslate = async () => {
    if (companiesInfo?.about) {
      let result = await GetTranslate(companiesInfo?.about);
      if (result?.status) {
        setAboutCompany(result?.text);
      }

      if (companiesInfo?.keyPoints) {
        let result = await GetTranslate(companiesInfo?.keyPoints);
        if (result?.status) {
          setKeyPointsCompany(result?.text);
        }
      }
    }
  };

  const fetchChartJSON = async (code) => {
    const response = await axios.get(
      `https://storage.sambalado.xyz/redbel-history/${code}.json`
    );
    if (response?.data?.length > 0) {
      await setCompaniesChart(response?.data);
      await setCompaniesCompare([
        {
          name: response?.data[0].company_name,
          code: response?.data[0].company_code,
          data: response?.data,
        },
      ]);
      // const resultData = await onAvarageData(response?.data);

      await onSingleChart(response?.data, categoryChartSelected, dateSelected);
      await onTradingView(response?.data);
    } else {
      await setCompaniesChart([]);
    }
  };

  const [fetchDocument] = useLazyQuery(QuerySummary, {
    onCompleted: async (data) => {
      await setAnnouncements(data?.company[0].announcement);
    },
  });

  useEffect(() => {
    fetchData();
    fetchChartJSON(companyId);
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    await fetchSummary();
  };

  const onCapitalStructure = async (data) => {
    const data_capital = { ...data };
    setSeriesCapitalStructure([
      {
        data: [
          {
            name: t("detail.market_cap"),
            y: data_capital?.marketCapitalization,
            color: "#23B19B",
          },
          {
            name: t("detail.debt"),
            y: data_capital?.debt,
            color: "#FEA726",
          },

          {
            name: t("detail.equivalents"),
            y: data_capital?.cashEquivalents,
            color: "#FF3F80",
          },
          {
            name: t("detail.enterprise_value"),
            y:
              data_capital?.marketCapitalization +
              data_capital?.debt +
              data_capital?.cashEquivalents,
            color: "#3178F5",
            isSum: true,
          },
        ],

        pointPadding: 0,
      },
    ]);
  };
  const onDividendSummary = (data) => {
    setDataDividendSummary(data);
    setSeriesDividendSummary([
      {
        name: "Dividend Summary",
        colorByPoint: true,
        data: [
          {
            name: "Payout Ratio",
            y: data?.payoutRatioTtm,
            color: "#23B19B",
          },
          {
            name: "Earnings Retained",
            y: 100 - data?.payoutRatioTtm,
            color: "#E0E3EB",
          },
        ],
      },
    ]);
  };
  const onDividendHistory = (data) => {
    const newData = [...data];
    const sortedData = newData.sort((a, b) => a?.yearBook - b?.yearBook);
    const years = sortedData.map((item) => item.yearBook);
    const dividendPerShare = sortedData.map((item) => item.dividendPerShare);
    const dividendYield = sortedData.map((item) => item.dividendYield);
    setCategoriesDividendHistory(years);
    setSeriesDividendHistory([
      {
        name: t("detail.dividend_yield"),
        type: "line",
        data: dividendYield,
        tooltip: {
          valueSuffix: "%",
        },
        color: "#FF9800",
        zIndex: 1,
      },
      {
        name: t("detail.diviend_per_share"),
        type: "column",
        data: dividendPerShare,
        tooltip: {
          valueSuffix: "",
        },
        color: "#42BCA8",
        yAxis: 1,
      },
    ]);
  };

  const onChooseDate = async (data) => {
    await setDateSelected(data);
    await onSingleChart(companiesChart, categoryChartSelected, data);
    await onCompareChart(companiesCompare, data);
  };

  const onTradingView = (data) => {
    let newData = data.map((item) => [
      new Date(item.date_created).getTime(),
      item.opening_price,
      item.highest_price,
      item.lowest_price,
      item.closing_price,
    ]);

    setSeriesTradingView(newData);
  };

  const onAverageBy = async (average, new_data) => {
    let groupedData = [];
    switch (average) {
      case "daily":
        for (let i = 0; i < new_data.length; i += 1) {
          const group = new_data.slice(i, i + 1);
          const averageGroup = {};
          // Hitung rata-rata untuk semua field numerik
          Object.keys(group[0]).forEach((key) => {
            if (typeof group[0][key] === "number") {
              const avgValue =
                group.reduce((sum, item) => sum + item[key], 0) / group.length;
              averageGroup[key] = avgValue;
            }
          });

          groupedData.push({
            categoryName: moment(group[0].date_created).format("DD MMM YY"),
            date_created: group[0].date_created,
            company_code: group[0].company_code,
            start_date: group[0].date_created,
            end_date: group[group.length - 1].date_created,
            company_name: group[0].company_name,
            ...averageGroup,
          });
        }
        return groupedData;
      case "weekly":
        const groupedByWeek = [];
        let currentWeek = [];
        let startOfWeek = moment(new_data[0].date_created).startOf("isoWeek"); // Mulai dari minggu pertama
        new_data.forEach((item) => {
          const itemDate = moment(item.date_created);
          const endOfWeek = startOfWeek.clone().add(7, "days");
          if (itemDate.isAfter(endOfWeek)) {
            // Simpan data minggu sebelumnya
            groupedByWeek.push(currentWeek);
            currentWeek = [];
            startOfWeek = startOfWeek.clone().add(1, "weeks");
          }
          currentWeek.push(item);
        });
        // Tambahkan minggu terakhir ke grup
        if (currentWeek.length > 0) groupedByWeek.push(currentWeek);

        // Hitung rata-rata untuk setiap minggu
        groupedByWeek.forEach((group, index, array) => {
          const averageResult = {};
          Object.keys(group[0]).forEach((key) => {
            if (typeof group[0][key] === "number") {
              const avgValue =
                group.reduce((sum, item) => sum + item[key], 0) / group.length;
              averageResult[key] = avgValue;
            }
          });

          const startDate = moment(group[0].date_created);
          const weekOfMonth = Math.ceil(startDate.date() / 8);
          const monthName = startDate.format("MMM YYYY");
          const weekName = `W${weekOfMonth} ${monthName}`;

          groupedData.push({
            categoryName: weekName,
            date_created: group[0].date_created,
            company_code: group[0].company_code,
            company_name: group[0].company_name,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: moment(group[group.length - 1].date_created).format(
              "YYYY-MM-DD"
            ),
            ...averageResult,
          });
        });
        return groupedData;
      case "monthly":
        const groupedByMonth = {};
        new_data.forEach((item) => {
          const monthKey = moment(item.date_created).format("YYYY-MM");
          if (!groupedByMonth[monthKey]) groupedByMonth[monthKey] = [];
          groupedByMonth[monthKey].push(item);
        });

        Object.entries(groupedByMonth).forEach(([monthKey, group]) => {
          const averageResult = {};
          Object.keys(group[0]).forEach((key) => {
            if (typeof group[0][key] === "number") {
              const avgValue =
                group.reduce((sum, item) => sum + item[key], 0) / group.length;
              averageResult[key] = avgValue;
            }
          });

          groupedData.push({
            categoryName: moment(monthKey).format("MMM YYYY"),
            date_created: group[0].date_created,
            company_code: group[0].company_code,
            company_name: group[0].company_name,
            start_date: `${monthKey}-01`,
            end_date: moment(`${monthKey}-01`)
              .endOf("month")
              .format("YYYY-MM-DD"),
            ...averageResult,
          });
        });
        return groupedData;
      case "quarterly":
        const groupedByQuarter = {};
        new_data.forEach((item) => {
          const year = moment(item.date_created).year();
          const month = moment(item.date_created).month() + 1;
          const quarter =
            month <= 3 ? "Q1" : month <= 6 ? "Q2" : month <= 9 ? "Q3" : "Q4";
          const quarterKey = ` ${quarter} ${year}`;

          if (!groupedByQuarter[quarterKey]) groupedByQuarter[quarterKey] = [];
          groupedByQuarter[quarterKey].push(item);
        });

        // Calculate averages for each quarter
        Object.entries(groupedByQuarter).forEach(([quarterKey, group]) => {
          const averageResult = {};
          Object.keys(group[0]).forEach((key) => {
            if (typeof group[0][key] === "number") {
              const avgValue =
                group.reduce((sum, item) => sum + item[key], 0) / group.length;
              averageResult[key] = avgValue;
            }
          });

          groupedData.push({
            categoryName: quarterKey,
            date_created: group[0].date_created,
            company_code: group[0].company_code,
            company_name: group[0].company_name,
            start_date: moment(group[0].date_created)
              .startOf("quarter")
              .format("YYYY-MM-DD"),
            end_date: moment(group[0].date_created)
              .endOf("quarter")
              .format("YYYY-MM-DD"),
            ...averageResult,
          });
        });

        return groupedData;
      case "halfYearly":
        const groupedByHalfYear = {};
        new_data.forEach((item) => {
          const year = moment(item.date_created).year();
          const month = moment(item.date_created).month() + 1;
          const halfYear = month <= 6 ? "Q1 - Q2" : "Q3 - Q4";
          const halfYearKey = ` ${halfYear} ${year}`;

          if (!groupedByHalfYear[halfYearKey])
            groupedByHalfYear[halfYearKey] = [];
          groupedByHalfYear[halfYearKey].push(item);
        });

        // Calculate averages for each half year
        Object.entries(groupedByHalfYear).forEach(([halfYearKey, group]) => {
          const averageResult = {};
          Object.keys(group[0]).forEach((key) => {
            if (typeof group[0][key] === "number") {
              const avgValue =
                group.reduce((sum, item) => sum + item[key], 0) / group.length;
              averageResult[key] = avgValue;
            }
          });

          groupedData.push({
            categoryName: halfYearKey,
            date_created: group[0].date_created,
            company_code: group[0].company_code,
            company_name: group[0].company_name,
            start_date: moment(group[0].date_created)
              .startOf("year")
              .format("YYYY-MM-DD"),
            end_date: moment(group[0].date_created)
              .endOf("year")
              .format("YYYY-MM-DD"),
            ...averageResult,
          });
        });
        return groupedData;
      default:
        const groupedByYear = {};
        new_data.forEach((item) => {
          const yearKey = moment(item.date_created).format("YYYY");
          if (!groupedByYear[yearKey]) groupedByYear[yearKey] = [];
          groupedByYear[yearKey].push(item);
        });
        // Calculate averages
        Object.entries(groupedByYear).forEach(
          ([yearKey, group], index, array) => {
            const averageResult = {};
            Object.keys(group[0]).forEach((key) => {
              if (typeof group[0][key] === "number") {
                const avgValue =
                  group.reduce((sum, item) => sum + item[key], 0) /
                  group.length;
                averageResult[key] = avgValue;
              }
            });

            const isLastYear = index === array.length - 1;
            const endDate = isLastYear
              ? moment().format("YYYY-MM-DD")
              : `${yearKey}-12-31`;

            groupedData.push({
              categoryName: yearKey,
              date_created: group[0].date_created,
              company_code: group[0].company_code,
              company_name: group[0].company_name,
              start_date: `${yearKey}-01-01`,
              end_date: endDate,
              ...averageResult,
            });
          }
        );
        return groupedData;
    }
  };
  const onSingleChart = async (data, categorySelected, dateSelected) => {
    const categorySlug = categorySelected?.slug;
    const { dateFrom, dateTo, averageBy } = dateSelected;
    const startDate = moment(dateFrom);
    const endDate = moment(dateTo);
    const new_data = dateFrom
      ? data.filter((item) =>
          moment(item.date_created).isBetween(
            startDate,
            endDate,
            undefined,
            "[]"
          )
        )
      : data;

    let new_data_average = await onAverageBy(averageBy, new_data);

    const actions = {
      price: () => {
        onPriceChart(new_data_average);
      },
      priceToEarning: () => {
        onPriceToEarningChart(new_data_average);
      },
      earningPerShare: () => {
        onEarningPerShareChart(new_data_average);
      },
      dividendYield: () => {
        // newData = data?.stockHistories;
        onDividendYieldChart(new_data_average);
      },
      marketCapitalization: () => {
        // newData = data?.stockHistories;
        onMarketCapitalizationChart(new_data_average);
      },
      ROCE: () => {
        // newData = data?.stockHistories;
        onROCEChart(new_data_average);
      },
    };
    if (actions[categorySlug]) {
      actions[categorySlug]();
    }
  };

  const onPriceChart = (data) => {
    const name = data?.map((item) => item.categoryName);

    const volumes = data?.map((item) => item.trade_volume);
    const closingPrices = data?.map((item) => item.closing_price);
    const ema50 = data?.map((item) => item.ema_50);
    const ema100 = data?.map((item) => item.ema_100);
    const ema200 = data?.map((item) => item.ema_200);
    const sma50 = data?.map((item) => item.sma_50);
    const sma100 = data?.map((item) => item.sma_100);
    const sma200 = data?.map((item) => item.sma_200);
    setCategoryOptionChart(name);
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b>  ${isFloat(
            point.y
          )}</b>`;
        });

        return tooltipText;
      },
    });
    setSeriesChart([
      {
        name: t("detail.volume"),
        type: "column",
        data: volumes,
        yAxis: 0,
        tooltip: {
          valueSuffix: " shares",
        },
      },
      {
        name: t("detail.price"),
        type: "line",
        data: closingPrices,
        tooltip: {
          valueSuffix: " IDR",
        },
        yAxis: 1,
      },
      {
        name: t("detail.EMA50"),
        type: "line",
        data: ema50,
        visible: false,
        yAxis: 1,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.EMA100"),
        type: "line",
        data: ema100,
        visible: false,
        yAxis: 1,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.EMA200"),
        type: "line",
        data: ema200,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA50"),
        type: "line",
        data: sma50,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA100"),
        type: "line",
        data: sma100,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
      {
        name: t("detail.SMA200"),
        type: "line",
        data: sma200,
        yAxis: 1,
        visible: false,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);
    setYAxisChart([
      {
        title: {
          text: "Volume",
        },
      },
      {
        title: {
          text: "Price",
        },
        opposite: true,
      },
    ]);
  };

  const onPriceToEarningChart = (data) => {
    // const dates = data.map((item) => item.date_created);
    const name = data?.map((item) => item.categoryName);
    const priceToEarning = data.map((item) => item.price_to_earning);
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b>  ${isFloat(
            point.y
          )}</b>`;
        });

        return tooltipText;
      },
    });
    setCategoryOptionChart(name);
    setSeriesChart([
      {
        name: t("detail.priceToEarning"),
        type: "line",
        data: priceToEarning,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ]);

    setYAxisChart([
      {
        opposite: true,
        title: {
          text: t("detail.priceToEarning"),
        },
      },
    ]);
  };

  const onEarningPerShareChart = (data) => {
    const name = data?.map((item) => item.categoryName);
    const eps = data.map((item) => item.earning_per_share);
    setCategoryOptionChart(name);
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b>  ${isFloat(
            point.y
          )}</b>`;
        });

        return tooltipText;
      },
    });
    setSeriesChart([
      {
        name: t("detail.earningPerShare"),
        type: "column",
        data: eps,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        opposite: true,
        title: {
          text: t("detail.earningPerShare"),
        },
      },
    ]);
  };

  const onDividendYieldChart = (data) => {
    const name = data?.map((item) => item.categoryName);
    const dividendYield = data.map((item) => item.dividend_yield);
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b>  ${isFloat(
            point.y
          )} %</b>`;
        });

        return tooltipText;
      },
    });
    setCategoryOptionChart(name);
    setSeriesChart([
      {
        name: t("detail.dividendYield"),
        type: "line",
        data: dividendYield,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ]);

    setYAxisChart([
      {
        opposite: true,
        title: {
          text: t("detail.dividendYield"),
        },
      },
    ]);
  };

  const onMarketCapitalizationChart = (data) => {
    const name = data?.map((item) => item.categoryName);
    const marketCapitalization = data?.map(
      (item) => item.market_capitalization
    );

    setCategoryOptionChart(name);
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b> Rp ${isFloat(
            point.y
          )} % </b>`;
        });

        return tooltipText;
      },
    });
    setSeriesChart([
      {
        name: t("detail.marketCapitalization"),
        type: "line",
        data: marketCapitalization,
        tooltip: {
          valueSuffix: " IDR",
        },
      },
    ]);

    setYAxisChart([
      {
        opposite: true,
        title: {
          text: t("detail.marketCapitalization"),
        },
      },
    ]);
  };

  const onROCEChart = (data) => {
    const name = data?.map((item) => item.categoryName);
    const return_on_capital_employed = data?.map(
      (item) => item.return_on_capital_employed
    );
    setToolTipChart({
      shared: true,
      intersect: false,
      formatter: function () {
        let tooltipText = "";
        tooltipText += `<p >${this.x}</p>`;
        this.points.forEach(function (point) {
          tooltipText += `<br>${point.series.name}:<b>  ${isFloat(
            point.y
          )} %</b>`;
        });

        return tooltipText;
      },
    });
    setCategoryOptionChart(name);
    setSeriesChart([
      {
        name: t("detail.ROCE"),
        type: "line",
        data: return_on_capital_employed,
        tooltip: {
          valueSuffix: " %",
        },
      },
    ]);

    setYAxisChart([
      {
        opposite: true,
        title: {
          text: t("detail.ROCE"),
        },
      },
    ]);
  };

  const onCompound = (compound) => {
    const newCompound = { ...compound };

    setCompoundSalesGrowth(newCompound?.compoundSalesGrowth);
    setDataSalesGrowth({
      labels: newCompound?.compoundSalesGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Sales Growth (%)",
          data: newCompound?.compoundSalesGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsSalesGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PROFIT GROWTH
    setCompoundProfitGrowth(newCompound?.compoundProfitGrowth);
    setDataProfitGrowth({
      labels: newCompound?.compoundProfitGrowth?.map((item) => item.period),
      datasets: [
        {
          label: " Profit Growth (%)",
          data: newCompound?.compoundProfitGrowth?.map(
            (item) => item.percentage
          ),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsProfitGrowth({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // PRICE
    setCompoundStockPriceCagr(newCompound?.stockPriceCagr);
    setDataStockPriceCagr({
      labels: newCompound?.stockPriceCagr?.map((item) => item.period),
      datasets: [
        {
          label: " Price CAGR",
          data: newCompound?.stockPriceCagr?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsStockPriceCagr({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });

    // ROE
    setReturnOnEquity(newCompound?.returnOnEquity);
    setDataReturnOnEquity({
      labels: newCompound?.returnOnEquity?.map((item) => item.period),
      datasets: [
        {
          label: " ROE (%)",
          data: newCompound?.returnOnEquity?.map((item) => item.percentage),
          borderColor: "#4a90e2",
          backgroundColor: "rgba(74, 144, 226, 0.2)",
          pointBackgroundColor: "#4a90e2",
          fill: false,
          tension: 0.4,
          pointRadius: 6,
          pointHoverRadius: 8,
        },
      ],
    });
    setOptionsReturnOnEquity({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
        },
        datalabels: {
          display: true,
          align: "top",
          backgroundColor: "#E1EFFF",
          color: "#0078FF",
          font: {
            size: 12,
            weight: "bold",
          },
          formatter: (value) => `${value}%`,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
    });
  };

  const onQuarterIncome = (quarterlyIncome) => {
    // field dates
    const fields = [...quarterlyIncome]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1;
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });

    setFieldQuarter(fieldQuarter);

    // sort data quarter
    const data = [...quarterlyIncome];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1;
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });

    setQuarter(dataQuarter);
  };

  const onCheckedCompare = async (code) => {
    let codes = [...codesCompare];
    let compares = [...companiesCompare];

    const index = codes.findIndex((item) => item === code);

    if (index !== -1) {
      codes.splice(index, 1);
      compares.splice(index, 1);
    } else {
      const response = await axios.get(
        `https://storage.sambalado.xyz/redbel-history/${code}.json`
      );
      if (response?.data?.length > 0) {
        codes.push(code);
        compares.push({
          code: response?.data[0]?.company_name,
          name: response?.data[0]?.company_name,
          data: response?.data,
        });
      }
    }
    setIsCompareActive(codes.length > 1);
    await setCodesCompare(codes);
    await setCompaniesCompare(compares);
    await onCompareChart(compares, dateSelected);
  };

  const onSubmitCompare = async () => {
    setIsOpenCompre(false);
  };

  const onCompareChart = async (data, dateSelected) => {
    const { dateFrom, dateTo, averageBy } = dateSelected;
    const startDate = moment(dateFrom);
    const endDate = moment(dateTo);
    let dataLoop = await Promise.all(
      data.map(async (item) => {
        const filteredData = dateFrom
          ? item?.data?.filter((entry) =>
              moment(entry.date_created).isBetween(
                startDate,
                endDate,
                undefined,
                "[]"
              )
            )
          : item?.data;

        const groupedData = await onAverageBy(averageBy, filteredData);

        return {
          code: item?.code,
          name: item?.name,
          data: groupedData,
        };
      })
    );

    let slug = "";
    switch (categoryChartSelected.slug) {
      case "price":
        slug = "price";
        break;
      case "priceToEarning":
        slug = "price_to_earning";
        break;
      case "earningPerShare":
        slug = "earning_per_share";
        break;
      case "dividendYield":
        slug = "dividend_yield";
        break;
      case "marketCapitalization":
        slug = "market_capitalization";
        break;
      default:
        slug = "price";
        break;
    }
    const chartData = dataLoop.map((item) => ({
      name: item.name,
      data: dateFrom
        ? item.data
            ?.filter((i) =>
              moment(i.date_created).isBetween(
                startDate,
                endDate,
                undefined,
                "[]"
              )
            )
            .map((entry) => [entry[slug]])
        : item.data.map((entry) => [entry[slug]]),
    }));

    let categories = dataLoop.map((item) => {
      return item.data
        ?.filter((i) =>
          moment(i.date_created).isBetween(startDate, endDate, undefined, "[]")
        )
        .map((entry) => entry.categoryName);
    });

    let pointFormat =
      slug === "dividend_yield"
        ? "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y} %</b><br/>"
        : "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>";

    setOptionCompare({
      chart: {
        height: 550,
        zoomType: "xy",
        panning: true,
        panKey: null,
        backgroundColor: state.darkMode ? "#212531" : "#ffffff",
      },
      rangeSelector: {
        enabled: false,
      },
      title: {
        text: "",
      },
      tooltip: {
        pointFormat,
        valueDecimals: 2,
      },
      xAxis: {
        categories:
          categories.length > 0 &&
          categories[0].map((item) => {
            return item;
          }),

        type: "datetime",
        labels: {
          style: {
            color: state.darkMode ? "#fff" : "#000",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          style: {
            color: state.darkMode ? "#fff" : "#000",
          },
        },
      },
      navigator: {
        enabled: true,
      },
      series: chartData,
      legend: {
        enabled: true,
        itemStyle: {
          color: state.darkMode ? "#fff" : "#000",
        },
      },
    });
  };

  const isMinus = (value) => {
    if (value < 0) {
      return true;
    } else {
      return false;
    }
  };

  const onProfitLoss = (annualIncome) => {
    // field dates
    const fields = [...annualIncome]

      ?.filter((item_) => item_?.dateCreated !== null)
      .map((item) => {
        return item?.dateCreated;
      });
    fields.unshift("#");
    setFieldProfitLoss(fields);

    // sort data quarter
    const data = [...annualIncome]?.filter(
      (item_) => item_?.dateCreated !== null
    );
    setProfitLoss(data);
  };

  const onBalanceSheet = (balanceSheet) => {
    // field dates
    const fields = [...balanceSheet]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldBalanceSheet(fieldQuarter);

    // sort data quarter
    const data = [...balanceSheet];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });

    setBalanceSheet(dataQuarter);
  };

  const onCashFlow = (cashFlow) => {
    // field dates
    const fields = [...cashFlow]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldCashFlow(fieldQuarter);

    // sort data
    const data = [...cashFlow];
    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });
    setCashFlow(dataQuarter);
  };

  const onRatio = (ratio) => {
    // field dates
    const fields = [...ratio]?.map((item) => {
      return item?.dateCreated;
    });
    fields.unshift("#");

    const fieldQuarter = fields.map((item) => {
      const month = new Date(item).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item).format("YY");
      }

      return { dateCreated: item, quarter };
    });
    setFieldRatio(fieldQuarter);

    // sort data
    const data = [...ratio];

    const dataQuarter = data.map((item) => {
      const month = new Date(item.dateCreated).getMonth() + 1; // getMonth() returns 0-indexed month
      let quarter;

      if (month >= 1 && month <= 3) {
        quarter = "Q1 " + moment(item.dateCreated).format("YY");
      } else if (month >= 4 && month <= 6) {
        quarter = "Q2 " + moment(item.dateCreated).format("YY");
      } else if (month >= 7 && month <= 9) {
        quarter = "Q3 " + moment(item.dateCreated).format("YY");
      } else if (month >= 10 && month <= 12) {
        quarter = "Q4 " + moment(item.dateCreated).format("YY");
      }

      return { ...item, quarter };
    });
    setRatioData(dataQuarter);
  };

  const onShareHolding = (data) => {
    const filteredData = data;
    setShareHoldingPattern(filteredData);

    const labels = filteredData.map((item) => item.name);

    const percentages = filteredData.map((item) => item.percentage);
    setDataSetShareHoldingPattern({
      labels: labels,
      datasets: [
        {
          label: " Percentage",
          data: percentages,
          backgroundColor: labels.map((item) => isRandomColor()),
        },
      ],
    });

    setDataOptionsShareHoldingPattern({
      responsive: true,
      plugins: {
        legend: {
          position: "right",
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || "";
              if (label) {
                label += ": " + context.raw + "%";
              }
              return label;
            },
          },
          titleFont: {
            color: "white",
          },
          bodyFont: {
            color: "white",
          },
        },
        datalabels: {
          display: false,
        },
      },
    });
  };
  const formatFileName = (fileName) => {
    const withoutExtension = fileName.replace(/\.[^/.]+$/, "");
    const withSpaces = withoutExtension.replace(/[-_]/g, " ");
    const codesPattern = companyId;
    const regex = new RegExp(`\\b(Tahunan|${codesPattern})\\b`, "gi");
    const cleanedString = withSpaces.replace(regex, "").trim();

    return cleanedString.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const onAnnualReport = (data) => {
    let newData = [...data];

    let filterFinancial = newData
      ?.filter((item) => item?.report?.fileName.includes("FinancialStatement"))

      .map((item) => {
        return {
          dateCreated: item?.report?.dateCreated,
          title: formatFileName(item?.report?.fileName),
          fileName: item?.report?.fileName,
          filePath: item?.report?.filePath,
        };
      });
    setAnnualReports(filterFinancial);
  };

  const onFetchDocument = async (filter = "") => {
    fetchDocument({
      variables: {
        code: companyId || "",
        filterAnnouncement: filter,
      },
    });
  };

  const onModalMore = () => {
    return (
      <CModalSide isOpen={isOpenMore} setIsOpen={() => setIsOpenMore(false)}>
        <ModalHeader>
          <div className="d-flex flex-row gap-2">
            <span className="fw-bold font-large ">{companiesInfo?.name}</span>
            <div className="ms-2" onClick={() => setIsOpenMore(false)}>
              <IoCloseSharp size={20} />
            </div>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            overflowY: "auto",
          }}
        >
          <div>
            <p className="fw-semibold font-label">
              {t("detail.about")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: aboutCompany,
              }}
            />
          </div>
          <div>
            <p className="fw-semibold font-label">
              {t("detail.key_point")}{" "}
              <span className="font-small text-primary">{"[edit]"}</span>
            </p>
            <span
              className={`${
                state?.darkMode ? "text-white" : "text-secondary"
              } font-label`}
              dangerouslySetInnerHTML={{
                __html: keyPointsCompany,
              }}
            />
          </div>
        </ModalBody>
      </CModalSide>
    );
  };

  const onModalCompare = () => {
    return (
      <Modal
        isOpen={isOpenCompare}
        toggle={() => setIsOpenCompre(false)}
        size="lg"
      >
        <ModalHeader toggle={() => setIsOpenCompre(false)} className="border-0">
          {t("detail.compare")}{" "}
          {i18n.language === "id"
            ? t(categoryChartSelected?.slug)
            : categoryChartSelected?.label}{" "}
          {t("with_others")}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row flex-wrap">
            {peerComparison
              ?.filter((item) => item?.code !== companyId)
              .map((item, index) => {
                return (
                  <label key={index} className="col-sm-6 col-12 mb-1">
                    <input
                      type="checkbox"
                      checked={
                        codesCompare?.includes(item?.code) ? true : false
                      }
                      onChange={() => onCheckedCompare(item?.code)}
                    />{" "}
                    {item?.name}
                  </label>
                );
              })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onSubmitCompare()}>
            DONE
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const toggleTooltip = (slug) => {
    setTooltipOpenPeer((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  const onShowChild = (slug) =>
    setObjectQuarter(
      objectQuarter.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildPL = (slug) =>
    setObjectProfitLoss(
      objectProfitLoss.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildBalanceSheet = (slug) =>
    setObjectBalanceSheet(
      objectBalanceSheet.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: true, isOpen: !item.isOpen }
          : item
      )
    );

  const onShowChildCashFlow = (slug) =>
    setObjectCashFlow(
      objectCashFlow.map((item) =>
        item.childOf === slug
          ? { ...item, isShow: !item.isShow }
          : item.slug === slug
          ? { ...item, isBold: !item.isBold, isOpen: !item.isOpen }
          : item
      )
    );

  return (
    <div>
      <MetaHelmet
        title={companiesInfo?.name || ""}
        description={companiesInfo?.about || ""}
      />
      {loading && <CLoading />}
      {onModalMore()}
      {onModalCompare()}
      {/* Section Summary */}
      <section id="summary">
        <SectionSummary
          companiesDetail={companiesInfo}
          darkMode={state?.darkMode}
          t={t}
          aboutCompany={aboutCompany}
          keyPointsCompany={keyPointsCompany}
          currentLang={i18n.language}
          isMinus={isMinus}
          isExpandedAbout={isExpandedAbout}
          setIsExpandedAbout={setIsExpandedAbout}
          setIsOpenMore={setIsOpenMore}
          isOpenMore={isOpenMore}
        />
      </section>
      {/* Section Capital Structur & Summary */}
      <section id="capital-structur" className="mt-3">
        <Row>
          {/* Capital Structure */}
          <Col sm="12" md="12" lg="6">
            <Card className="h-100">
              <CardBody>
                <p className="font-title fw-semibold">
                  {t("detail.capital_structure")}
                </p>
                <div className="d-flex flex-sm-row flex-column gap-3">
                  <div>
                    <SectionChartCapitalStruktur
                      series={seriesCapitalStructure}
                    />
                  </div>
                  <div className="d-flex flex-column ">
                    {seriesCapitalStructure?.length > 0 &&
                      seriesCapitalStructure[0]?.data?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: 300,
                              borderTop:
                                item?.name?.includes("enterprise_value") &&
                                "1px solid rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div className="d-flex flex-row justify-content-between">
                              <div className="col-sm-5 col-5">
                                <span className="font-description ">
                                  {item?.name}
                                </span>
                              </div>
                              <div>
                                <span className="font-description fw-bold">
                                  {isCurrency(item?.y)}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* Divident Summary */}
          <Col sm="12" md="12" lg="6">
            <Card className="mt-sm-0 mt-2">
              <CardBody>
                <p className="font-title fw-semibold">
                  {t("detail.divident_summary")}
                </p>
                <div className="d-flex flex-sm-row flex-column gap-3">
                  <div>
                    <SectionChartDivSummary
                      data={dataDividendSummary}
                      series={seriesDividendSummary}
                    />
                  </div>
                  <div>
                    <div className="d-flex flex-row gap-3">
                      <span className="font-description">
                        {t("detail.dividend_yield")} TTM
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.dividendYieldTtm?.toFixed(2)}%
                      </span>
                    </div>
                    <div className="d-flex flex-row gap-3">
                      <span className="font-description">
                        {t("detail.next_payment")}
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.nextPayment
                          ? moment(dataDividendSummary?.nextPayment).format(
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </span>
                    </div>
                    <div className="d-flex flex-row gap-3">
                      <span className="font-description">
                        {t("detail.next_ex_date")}
                      </span>
                      <span className="font-description fw-bold">
                        {dataDividendSummary?.nextExDate
                          ? moment(dataDividendSummary?.nextExDate).format(
                              "MMM DD, YYYY"
                            )
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className="mt-3">
          <CardBody>
            <div>
              <p className="font-title fw-semibold">
                {t("detail.dividend_history")}
              </p>
            </div>
            <SectionChartDivHistory
              series={seriesDividendHistory}
              categories={categoriesDividendHistory}
            />
          </CardBody>
        </Card>
      </section>
      {/* Section Chart */}
      <section id="chart" className="mt-3">
        <Card>
          <CardBody>
            <div className="d-flex flex-lg-row-reverse flex-column justify-content-between">
              <div>
                <div className="py-2  px-2 ">
                  <span className="font-label ">
                    <HiOutlinePresentationChartLine size={20} />{" "}
                    {t("detail.switch_to_tradingview")}
                  </span>
                  <label className="switch ms-2">
                    <input
                      type="checkbox"
                      checked={isTradingView}
                      onChange={() => setIsTradingView(!isTradingView)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {!isTradingView && (
                <div>
                  <div className="d-flex flex-row gap-2">
                    <div className="horizontal-list-container">
                      <Nav className="horizontal-list gap-2" pills>
                        <NavItem
                          onClick={() => setIsOpenCompre(true)}
                          className={
                            isCompareActive
                              ? "filter-chart-active"
                              : "filter-chart"
                          }
                        >
                          <div>
                            <span>{t("detail.compare")} </span>{" "}
                            <span>{t(categoryChartSelected.slug)}</span>
                          </div>
                        </NavItem>
                        {categoryChart?.map((item) => (
                          <NavItem
                            onClick={async () => {
                              await setCategoryChartSelected(item);
                              await setCompaniesCompare([
                                {
                                  name: companiesChart[0]?.company_name,
                                  code: companiesChart[0]?.company_code,
                                  data: companiesChart,
                                },
                              ]);
                              await setIsCompareActive(false);
                              await setCodesCompare([companyId]);
                              await onSingleChart(
                                companiesChart,
                                item,
                                dateSelected
                              );
                            }}
                            key={item?.slug}
                            className={
                              categoryChartSelected?.slug === item?.slug &&
                              !isCompareActive
                                ? "filter-chart-active"
                                : "filter-chart"
                            }
                          >
                            <div>{t("detail." + item.slug)}</div>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!isTradingView && (
              <div>
                <ButtonGroup className="my-2 border btn-sm btn-lg">
                  {dateChart?.map((item) => (
                    <Button
                      key={item?.label}
                      onClick={() => onChooseDate(item)}
                      className={
                        dateSelected?.label === item?.label
                          ? "text-primary border-0 shadow-none font-label"
                          : state?.darkMode
                          ? "text-white border-0 shadow-none font-label"
                          : "text-secondary border-0 shadow-none font-label"
                      }
                      style={{
                        backgroundColor:
                          dateSelected?.label === item?.label
                            ? state?.darkMode
                              ? "#2609b5"
                              : "#F3F4FF"
                            : state?.darkMode
                            ? "#606f7b"
                            : "#FFF",

                        fontWeight: "500",
                      }}
                    >
                      {item?.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
            )}

            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "300px",
                  width: "100%",
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <Spinner color="primary" />
                  </div>
                  <div>
                    <p className="font-label fw-semibold">Get data...</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {isTradingView ? (
                  <SectionChartTrading seriesTrading={seriesTradingView} />
                ) : isCompareActive ? (
                  <SectionChartCompare t={t} optionCompare={optionCompare} />
                ) : !isTradingView && !isCompareActive ? (
                  <SectionChart
                    t={t}
                    seriesChart={seriesChart}
                    categoriesChart={categoryOptionChart}
                    tooltipChart={tooltipChart}
                    yAxisChart={yAxisChart}
                  />
                ) : null}

                {/* <ChartView
                  isTradingView={isTradingView}
                  isCompareActive={isCompareActive}
                  seriesTradingView={seriesTradingView}
                  optionCompare={optionCompare}
                  seriesChart={seriesChart}
                  categoryOptionChart={categoryOptionChart}
                  yAxisChart={yAxisChart}
                  tooltipChart={tooltipChart}
                /> */}
              </div>
            )}
          </CardBody>
        </Card>
      </section>
      {/* <section id="analysis">
        <SectionAnalys
          companiesDetail={companiesInfo}
          t={t}
          darkMode={state?.darkMode}
        />
      </section> */}
      {/* Section Compound */}
      <Row className="mt-4">
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.SALESGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line data={dataSalesGrowth} options={optionsSalesGrowth} />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundSalesGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">
                  {t("detail.PROFITGROWTH")} %
                </p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line data={dataProfitGrowth} options={optionsProfitGrowth} />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundProfitGrowth?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">{t("detail.PRICECAGR")} %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataStockPriceCagr}
                  options={optionsStockPriceCagr}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {compoundstockPriceCagr?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" md="6" lg="3">
          <Card>
            <CardBody>
              <div>
                <p className="font-title fw-bold">ROE %</p>
              </div>
              <div
                style={{
                  height: "150px",
                }}
              >
                <Line
                  data={dataReturnOnEquity}
                  options={optionsReturnOnEquity}
                />
              </div>
              {/* Custom labels below the chart */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "10px",
                }}
              >
                {returnOnEquity?.map((item, index) => (
                  <div key={index} style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      {item?.period}
                    </div>

                    <div className="fw-semibold font-description">
                      {item?.percentage}%
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* Section Peer comparison */}
      <section id="peers">
        <SectionPeer
          companiesDetail={companiesInfo}
          t={t}
          darkMode={state.darkMode}
          fieldPeerComparison={fieldPeerComparison}
          tooltipOpenPeer={tooltipOpenPeer}
          toggleTooltip={toggleTooltip}
          peerComparison={peerComparison}
        />
      </section>
      {/* Section QuarterResult */}
      <section id="quarters">
        <SectionQuarter
          t={t}
          darkMode={state.darkMode}
          quarter={quarter}
          fieldQuarter={fieldQuarter}
          objectQuarter={objectQuarter}
          onShowChild={onShowChild}
        />
      </section>
      {/* Section Profit Loss */}
      <section id="profitLoss">
        <SectionProfitLoss
          t={t}
          darkMode={state.darkMode}
          profitLoss={profitLoss}
          fieldProfitLoss={fieldProfitLoss}
          objectProfitLoss={objectProfitLoss}
          onShowChildPL={onShowChildPL}
        />
      </section>
      {/* Section Balance Sheet */}
      <section id="balanceSheet">
        <SectionBalance
          t={t}
          balanceSheet={balanceSheet}
          fieldBalanceSheet={fieldBalanceSheet}
          objectBalanceSheet={objectBalanceSheet}
          darkMode={state.darkMode}
          onShowChildBalanceSheet={onShowChildBalanceSheet}
        />
      </section>
      {/* Section Cash Flows */}
      <section id="cashFlow">
        <SectionCashFlow
          t={t}
          darkMode={state.darkMode}
          cashFlow={cashFlow}
          fieldCashFlow={fieldCashFlow}
          objectCashFlow={objectCashFlow}
          onShowChildCashFlow={onShowChildCashFlow}
        />
      </section>

      {/* Section Ratio */}
      <section id="ratios">
        <SectionRatio
          ratio={ratio}
          t={t}
          darkMode={state.darkMode}
          fieldRatio={fieldRatio}
          objectRatio={objectRatio}
          ratioData={ratioData}
        />
      </section>

      {/* Section Shareholding */}
      <section id="investors">
        <SectionShareholding
          dataSetShareHoldingPattern={dataSetShareHoldingPattern}
          dataOptionsShareHoldingPattern={dataOptionsShareHoldingPattern}
          t={t}
          darkMode={state.darkMode}
          shareHoldingPattern={shareHoldingPattern}
        />
      </section>
      <section>
        <SectionDocument
          announcements={announcements}
          onFetchDocument={(filter) => onFetchDocument(filter)}
          annualReports={annualReports}
        />
      </section>

      {/* Section Forecast */}
      <section id="forecast">
        <SectionForecast />
      </section>
    </div>
  );
};

export default Company;
