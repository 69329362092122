import moment from "moment";
import React from "react";
import { FaFilePdf, FaMinus, FaPlus } from "react-icons/fa";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { NoData } from "../../../../assets/img";
import CEmpty from "../../../../components/CEmpty";
import { isCurrencyWithOutRp } from "../../../../utils/isCurrency";
import IsMobile from "../../../../utils/IsMobile";
import SectionChartProfit from "./SectionChartProfit";

const SectionProfitLoss = ({
  profitLoss,
  darkMode,
  t,
  fieldProfitLoss,
  objectProfitLoss,
  onShowChildPL,
}) => {
  const mobileView = IsMobile();
  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <Row className="d-flex flex-row justify-content-between align-items-center">
          <Col sm="12" md="6" lg="8" className="d-flex flex-column">
            <span className="font-extra-large fw-bold">
              {t("detail.profit_loss")}
            </span>
            <span className="text-secondary font-title">
              {t("detail.standalone_figures")}
              <span
                className="text-primary"
                style={{ fontSize: mobileView ? "14px" : "16px" }}
              >
                {" "}
                / {t("detail.view_consolidated")}
              </span>
            </span>
          </Col>
          {/* <Col sm="12" md="6" lg="4">
            <div className="d-flex justify-content-lg-end justify-content-md-start">
              <div className="border border-primary rounded px-2 py-1 d-flex flex-row align-items-center gap-2">
                <FaChartPie color="#665FFD" />
                <span
                  style={{
                    fontSize: mobileView ? "10px" : "13px",
                    fontWeight: "700",
                    color: "#665FFD",
                  }}
                >
                  PRODUCT SEGMENTS
                </span>
              </div>
            </div>
          </Col> */}
        </Row>
        <SectionChartProfit data={profitLoss} />
        {profitLoss?.length > 0 ? (
          <div className="table-container">
            <Table className={`detail-table ${darkMode && "table-dark"}`}>
              <thead>
                <tr className="quarter-tr">
                  {fieldProfitLoss?.map((date, index) => (
                    <th
                      key={index}
                      style={{
                        fontWeight: "500",
                        fontSize: mobileView ? "10px" : "12px",
                      }}
                    >
                      {date?.includes("#")
                        ? ""
                        : moment(date).format("MMM YYYY")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {objectProfitLoss.map((itemObj, indexObj) => {
                  if (itemObj?.isShow === true) {
                    return (
                      <tr key={indexObj} className="quarter-tr">
                        <td
                          onClick={() =>
                            itemObj?.haveChild && onShowChildPL(itemObj?.slug)
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight: itemObj?.isBold ? "bold" : "normal",
                            fontSize: mobileView ? "10px" : "12px",
                            backgroundColor: itemObj?.isBold
                              ? darkMode
                                ? " #2d2d2d"
                                : "white"
                              : itemObj.childOf
                              ? darkMode
                                ? " #2d2d2d"
                                : "#f6f6f6"
                              : darkMode
                              ? "#14161f"
                              : "#FFF",
                          }}
                        >
                          {t("annualIncome." + itemObj?.label)}{" "}
                          {itemObj?.haveChild ? (
                            itemObj?.isOpen ? (
                              <FaMinus size={8} color="#665FFD" />
                            ) : (
                              <FaPlus size={8} color="#665FFD" />
                            )
                          ) : (
                            ""
                          )}
                        </td>
                        {profitLoss.map((itemValue, indexValue) => (
                          <td
                            key={indexValue}
                            style={{
                              backgroundColor: itemObj?.isBold
                                ? darkMode
                                  ? " #2d2d2d"
                                  : "white"
                                : itemObj.childOf
                                ? darkMode
                                  ? " #2d2d2d"
                                  : "#f6f6f6"
                                : darkMode
                                ? "#14161f"
                                : "#FFF",
                            }}
                          >
                            {itemObj?.slug === "report" ? (
                              <a
                                href={
                                  itemValue[itemObj?.slug]
                                    ? itemValue[itemObj?.slug]["filePath"]
                                    : null
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <FaFilePdf size={14} color="#a9a9a9" />
                              </a>
                            ) : (
                              <span
                                style={{
                                  fontSize: mobileView ? "10px" : "12px",
                                  fontWeight: itemObj?.isBold
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                {itemObj?.prefix?.includes("%")
                                  ? itemValue[itemObj?.slug]?.toFixed(2)
                                  : isCurrencyWithOutRp(
                                      itemValue[itemObj?.slug]
                                    )}{" "}
                                {itemObj?.prefix?.includes("%") ? "%" : ""}
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
                <tr></tr>
              </tbody>
            </Table>
          </div>
        ) : (
          <CEmpty
            className="d-flex justify-content-center align-items-center flex-column"
            image={<img src={NoData} alt="No Data" width={250} />}
            title={t("detail.no_data")}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default SectionProfitLoss;
