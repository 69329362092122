import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Col, Row } from "reactstrap";
import CLoading from "../../components/CLoading";
import MetaHelmet from "../../components/MetaHelmet";
import UpdateProfile from "../../graphQL/Mutation/UpdateProfile";
import QueryProfile from "../../graphQL/Query/QueryProfile";
import QueryRank from "../../graphQL/Query/QueryRank";
import { isCurrencyWithOutRpFormat } from "../../utils/isCurrency";
import ChartMarket from "./chart/ChartMarket";

const Market = () => {
  const navigate = useNavigate();
  const [stockRank, setStockRank] = useState([]);
  const [volumeRank, setVolumeRank] = useState([]);
  const [gainerRank, setGainerRank] = useState([]);
  const [frequentRank, setFrequentRank] = useState([]);
  const [marketCapital, setMarketCapital] = useState([]);
  const [volumeOneWeekAverage, setVolumeOneWeekAverage] = useState([]);
  const [volumeOneMonthAverage, setVolumeOneMonthAverage] = useState([]);
  const [returnOverOneWeek, setReturnOverOneWeek] = useState([]);
  const [returnOverOneMonth, setReturnOverOneMonth] = useState([]);
  const [returnOverThreeMonth, setReturnOverThreeMonth] = useState([]);
  const [returnOverSixMonth, setReturnOverSixMonth] = useState([]);

  const [tooltipStockRank, setTooltipStockRank] = useState({});
  const [tooltipVolumeRank, setTooltipVolumeRank] = useState({});
  const [tooltipGainerRank, setTooltipGainerRank] = useState({});
  const [tooltipFrequentRank, setTooltipFrequentRank] = useState({});
  const [tooltipMarketCapital, setTooltipMarketCapital] = useState({});
  const [tooltipVolumeOneWeekAverage, setTooltipVolumeOneWeekAverage] =
    useState({});
  const [tooltipVolumeOneMonthAverage, setTooltipVolumeOneMonthAverage] =
    useState({});
  const [tooltipReturnOverOneWeek, setTooltipReturnOverOneWeek] = useState({});
  const [tooltipReturnOverOneMonth, setTooltipReturnOverOneMonth] = useState(
    {}
  );
  const [tooltipReturnOverThreeMonth, setTooltipReturnOverThreeMonth] =
    useState({});
  const [tooltipReturnOverSixMonth, setTooltipReturnOverSixMonth] = useState(
    {}
  );
  const { t } = useTranslation("global");

  const [dashboard_obj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : null
  );

  const [dashboard] = useState({
    ratio: {
      marketCapitalization: true,
      bookValue: true,
      returnOnEquity: true,
      dividendYield: true,
    },
    annualIncome: {
      returnOnCapitalEmployed: true,
    },
    price: {
      currentPrice: true,
      highPrice: true,
      lowPrice: true,
    },
    balanceSheet: {
      faceValue: true,
    },
  });

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const [fetchDashboard, { loadingDashboard }] = useLazyQuery(QueryProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: (data) => {
      const newDashboard = data?.profile?.userProfiles?.dashboard.reduce(
        (acc, curr) => {
          return {
            ...acc,
            [curr.section]: {
              ...acc[curr.section],
              [curr.value]: true,
            },
          };
        },
        {}
      );
      if (!isEmptyObject(newDashboard)) {
        secureLocalStorage.setItem(
          "dashboard_obj",
          JSON.stringify(newDashboard)
        );
        secureLocalStorage.setItem(
          "dashboard_arr",
          JSON.stringify(data?.profile?.userProfiles?.dashboard)
        );
      }
      let pageDirection = secureLocalStorage.getItem("pageDirection");
      if (pageDirection) {
        secureLocalStorage.removeItem("pageDirection");
        navigate(pageDirection);
      }
    },
  });

  const [updateRatio, { loading: loadingRatio }] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },

    onCompleted: () => {
      fetchDashboard();
    },
    onError: () => {
      console.log("Error update ratio first login");
    },
  });

  useEffect(() => {
    updateRatio({
      variables: {
        dashboard: dashboard_obj ? dashboard_obj : dashboard,
      },
    });
    //  eslint-disable-next-line
  }, []);

  const [fetchData, { loading }] = useLazyQuery(QueryRank);
  useEffect(() => {
    onFetchStockRank();
    onFetchVolumeRank();
    onFetchGainerRank();
    onFetchFrequentRank();
    onFetchMarketCapital();
    onFetchVolumeOneWeek();
    onFetchVolumeOneMonth();
    onFetchReturnOverOneWeek();
    onFetchReturnOverOneMonth();
    onFetchReturnOverThreeMonth();
    onFetchReturnOverSixMonth();
    // eslint-disable-next-line
  }, []);

  const onFetchStockRank = async () => {
    const result = await fetchData({
      variables: {
        type: "STOCK_RANK",
        limit: 5,
      },
    });

    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.price - a.price);
      setStockRank(data);
      setTooltipStockRank({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `  <b>${stock?.name}</b><br/>
                   ${t(
                     "market.current_price"
                   )}: <b>Rp ${isCurrencyWithOutRpFormat(
            stock?.price
          )} </b><br/>`;
        },
      });
    }
  };
  const onFetchVolumeRank = async () => {
    const result = await fetchData({
      variables: {
        type: "VOLUME_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.volume - a.volume);
      setVolumeRank(data);
      setTooltipVolumeRank({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `  <b>${stock?.name}</b><br/>
                    ${t("market.day_volume")}: <b>${isCurrencyWithOutRpFormat(
            stock?.volume
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchGainerRank = async () => {
    const result = await fetchData({
      variables: {
        type: "GAINER_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,

        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));

      setGainerRank(data);
      setTooltipGainerRank({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `  <b>${stock?.name}</b><br/>
                     ${t("market.day_change")}: <b>${stock?.change}%</b><br/>`;
        },
      });
    }
  };

  const onFetchFrequentRank = async () => {
    const result = await fetchData({
      variables: {
        type: "FREQUENT_RANK",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,

        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.tradeFrequency - a.tradeFrequency);
      setFrequentRank(data);
      setTooltipFrequentRank({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `  <b>${stock?.name}</b><br/>
                     ${t("market.frequent")}: <b>${isCurrencyWithOutRpFormat(
            stock?.tradeFrequency
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchMarketCapital = async () => {
    const result = await fetchData({
      variables: {
        type: "MARKET_CAPITAL",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.marketCapitalization - a.marketCapitalization);
      setMarketCapital(data);
      setTooltipMarketCapital({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.marketCapitalization"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.marketCapitalization
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchVolumeOneWeek = async () => {
    const result = await fetchData({
      variables: {
        type: "VOLUME_ONE_WEEK_AVERAGE",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.volume1WeekAverage - a.volume1WeekAverage);
      setVolumeOneWeekAverage(data);
      setTooltipVolumeOneWeekAverage({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.volume1WeekAverage"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.volume1WeekAverage
          )}</b><br/>`;
        },
      });
    }
  };
  const onFetchVolumeOneMonth = async () => {
    const result = await fetchData({
      variables: {
        type: "VOLUME_ONE_MONTH_AVERAGE",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.volume1MonthAverage - a.volume1MonthAverage);
      setVolumeOneMonthAverage(data);
      setTooltipVolumeOneMonthAverage({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                 ${t(
                   "market.volume1MonthAverage"
                 )}: <b>${isCurrencyWithOutRpFormat(
            stock?.volume1MonthAverage
          )}</b><br/>`;
        },
      });
    }
  };
  const onFetchReturnOverOneWeek = async () => {
    const result = await fetchData({
      variables: {
        type: "RETURN_OVER_ONE_WEEK",
        limit: 5,
      },
    });
    console.log("RES", result);
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.returnOver1Week - a.returnOver1Week);
      setReturnOverOneWeek(data);
      setTooltipReturnOverOneWeek({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.returnOver1Week"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.returnOver1Week
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchReturnOverOneMonth = async () => {
    const result = await fetchData({
      variables: {
        type: "RETURN_OVER_ONE_MONTH",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.returnOver1Month - a.returnOver1Month);
      setReturnOverOneMonth(data);
      setTooltipReturnOverOneMonth({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.returnOver1Month"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.returnOver1Month
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchReturnOverThreeMonth = async () => {
    const result = await fetchData({
      variables: {
        type: "RETURN_OVER_THREE_MONTH",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.returnOver3Months - a.returnOver3Months);
      setReturnOverThreeMonth(data);
      setTooltipReturnOverThreeMonth({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.returnOver3Months"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.returnOver3Months
          )}</b><br/>`;
        },
      });
    }
  };

  const onFetchReturnOverSixMonth = async () => {
    const result = await fetchData({
      variables: {
        type: "RETURN_OVER_SIX_MONTH",
        limit: 5,
      },
    });
    if (result?.data?.rank?.data) {
      const data = result?.data?.rank?.data?.map((item) => ({
        code: item.code,
        name: item.name,
        change:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.change,
        price:
          item.stockSummaries?.length === 0 ? 0 : item.stockSummaries[0]?.price,
        volume:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume,
        stockRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.stockRank,
        volumeRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volumeRank,
        gainerRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.gainerRank,
        frequentRank:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.frequentRank,
        volume1WeekAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1WeekAverage,
        volume1MonthAverage:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.volume1MonthAverage,
        marketCapitalization:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.marketCapitalization,
        tradeFrequency:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.tradeFrequency,
        returnOver1Week:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Week,
        returnOver1Month:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver1Month,
        returnOver3Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver3Months,
        returnOver6Months:
          item.stockSummaries?.length === 0
            ? 0
            : item.stockSummaries[0]?.returnOver6Months,
      }));
      // .sort((a, b) => b.returnOver6Months - a.returnOver6Months);
      setReturnOverSixMonth(data);
      setTooltipReturnOverSixMonth({
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        formatter: function () {
          const stock = data.find((item) => item?.code === this.key);
          return `<b>${stock?.name}</b><br/>
                  ${t(
                    "market.returnOver6Months"
                  )}: <b>${isCurrencyWithOutRpFormat(
            stock?.returnOver6Months
          )}</b><br/>`;
        },
      });
    }
  };
  return (
    <div>
      <MetaHelmet
        title="Stock Market"
        description={
          " See top gainers, volume stockers, top frequent & top stock"
        }
      />
      {loading && <CLoading />}
      {loadingDashboard && loadingRatio ? <CLoading /> : null}
      <div className="d-flex flex-column mb-2">
        <span className="font-extra-large fw-semibold">
          {t("market.title")}
        </span>
        <span className="font-label">{t("market.description")}</span>
      </div>
      <div className="d-flex flex-column ">
        <span className="font-large fw-semibold">Top Stock</span>
      </div>
      <Row>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={gainerRank}
            value="change"
            title={t("market.top_grainer")}
            name={t("market.change") + "%"}
            t={t}
            tooltip={tooltipGainerRank}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "GAINER_RANK",
                  slug: t("market.top_grainer"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={stockRank}
            value="price"
            title={t("market.stock_rank")}
            name={t("market.price")}
            t={t}
            tooltip={tooltipStockRank}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "STOCK_RANK",
                  slug: t("market.stock_rank"),
                },
              })
            }
          />
        </Col>

        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={frequentRank}
            value="tradeFrequency"
            title={t("market.top_frequency")}
            name={t("market.frequent")}
            t={t}
            tooltip={tooltipFrequentRank}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "FREQUENT_RANK",
                  slug: t("market.top_frequency"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={marketCapital}
            value="marketCapitalization"
            title={t("market.marketCapitalization")}
            name={t("market.marketCapitalization")}
            t={t}
            tooltip={tooltipMarketCapital}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "MARKET_CAPITAL",
                  slug: t("market.marketCapitalization"),
                },
              })
            }
          />
        </Col>
      </Row>
      <div className="d-flex flex-column mt-3">
        <span className="font-large fw-semibold">Volume Stockers</span>
      </div>
      <Row>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={volumeRank}
            value="volume"
            title={t("market.volume_stocker")}
            name={t("market.volume")}
            t={t}
            tooltip={tooltipVolumeRank}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "VOLUME_RANK",
                  slug: t("market.volume_stocker"),
                },
              })
            }
          />
        </Col>

        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={volumeOneWeekAverage}
            value="volume1WeekAverage"
            title={t("market.volume1WeekAverage")}
            name={t("market.volume1WeekAverage")}
            t={t}
            tooltip={tooltipVolumeOneWeekAverage}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "VOLUME_ONE_WEEK_AVERAGE",
                  slug: t("market.volume1WeekAverage"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-3">
          <ChartMarket
            data={volumeOneMonthAverage}
            value="volume1MonthAverage"
            title={t("market.volume1MonthAverage")}
            name={t("market.volume1MonthAverage")}
            t={t}
            tooltip={tooltipVolumeOneMonthAverage}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "VOLUME_ONE_MONTH_AVERAGE",
                  slug: t("market.volume1MonthAverage"),
                },
              })
            }
          />
        </Col>
      </Row>
      <div className="d-flex flex-column mt-3">
        <span className="font-large fw-semibold">Return Over</span>
      </div>
      <Row>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={returnOverOneWeek}
            value="returnOver1Week"
            title={t("market.returnOver1Week")}
            name={t("market.returnOver1Week")}
            t={t}
            tooltip={tooltipReturnOverOneWeek}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "RETURN_OVER_ONE_WEEK",
                  slug: t("market.returnOver1Week"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={returnOverOneMonth}
            value="returnOver1Month"
            title={t("market.returnOver1Month")}
            name={t("market.returnOver1Month")}
            t={t}
            tooltip={tooltipReturnOverOneMonth}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "RETURN_OVER_ONE_MONTH",
                  slug: t("market.returnOver1Month"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={returnOverThreeMonth}
            value="returnOver3Months"
            title={t("market.returnOver3Months")}
            name={t("market.returnOver3Months")}
            t={t}
            tooltip={tooltipReturnOverThreeMonth}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "RETURN_OVER_THREE_MONTH",
                  slug: t("market.returnOver3Months"),
                },
              })
            }
          />
        </Col>
        <Col sm="6" lg="3" md="6" className="mt-2">
          <ChartMarket
            data={returnOverSixMonth}
            value="returnOver6Months"
            title={t("market.returnOver6Months")}
            name={t("market.returnOver6Months")}
            t={t}
            tooltip={tooltipReturnOverSixMonth}
            onClick={() =>
              navigate(`/market/all/`, {
                state: {
                  type: "RETURN_OVER_SIX_MONTH",
                  slug: t("market.returnOver6Months"),
                },
              })
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default Market;
