import { gql } from "@apollo/client";

const StockScreenerRange = gql`
  query StockScreenerRange {
    stockScreenerRange {
      key
      displayName
      value {
        min
        max
      }
    }
  }
`;

export default StockScreenerRange;
