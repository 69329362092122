import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { isCurrencyNumber } from "../../../../utils/isCurrency";

const SectionChartCashFlow = ({ data }) => {
  const { state } = useContext(GlobalContext);
  const { darkMode } = state;

  const [options, setOptions] = useState({
    chart: {
      type: "column",
      backgroundColor: "transparent",

      height: 300,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [],
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "14px",
          color: darkMode ? "#fff" : "#000",
        },
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        groupPadding: 0.05,
        borderWidth: 0,
        maxPointWidth: 50,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "10px",
          color: darkMode ? "#fff" : "#000",
        },
      },
      opposite: true,
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        // Custom formatter for tooltip
        const points = this.points.map(
          (point) =>
            `<span style="color:${point.color}">● ${
              point.series.name
            }</span>: <b>Rp.${isCurrencyNumber(point.y)}</b>`
        );
        return `<span>${this.x}</span><br/>${points.join("<br/>")}`;
      },
    },
    series: [],
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",

      backgroundColor: "transparent",
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      xAxis: {
        ...options.xAxis,
        categories: data?.map((item) => item.quarter),
        labels: {
          ...options.xAxis.labels,
          style: {
            ...options.xAxis.labels.style,
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      yAxis: {
        ...options.yAxis,
        labels: {
          ...options.yAxis.labels,
          style: {
            ...options.yAxis.labels.style,
            color: darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Cash from Operating Activities",
          data: data?.map((item) => item?.cashFromOperationLatestPeriod),
          color: "#4489FF",
        },
        {
          name: "Cash from Investing Activities",
          data: data?.map((item) => item?.cashFromInvestingLatestPeriod),
          color: "#4ED0E0",
        },
        {
          name: "Cash from Investing Activities",
          data: data?.map((item) => item?.cashFromFinancingLatestPeriod),
          color: "#F57E16",
        },
      ],
    });
    // eslint-disable-next-line
  }, [data, darkMode]);

  return (
    <div style={{ overflowX: "auto" }} className="mt-3 ">
      <div style={{ minWidth: "800px" }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default SectionChartCashFlow;
