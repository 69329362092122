import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CScrollTop from "../components/CScrollTop";
import { pageview } from "../utils/gtag";
import Footer from "./components/Footer";
import Header from "./components/Header";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    pageview(pathname); // Track pageview
  }, [pathname]);

  return (
    <div className="layout-container">
      {/* Header Section */}
      <header>
        {/* <ResponsiveNavBar /> */}
        <Header />
      </header>
      {pathname.includes("login") || pathname.includes("register") ? (
        children
      ) : (
        <main className="content-container">{children}</main>
      )}
      {/* Content Section */}

      <CScrollTop />
      <Footer />
    </div>
  );
};
export default Layout;
