import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, FormGroup, Input, Label, Tooltip } from "reactstrap";
import CBreadcrumb from "../../components/CBreadcrumb";
import CLoading from "../../components/CLoading";
import CTableSector from "../../components/CTableSector";
import { GlobalContext } from "../../contexts/GlobalContext";
import QueryRank from "../../graphQL/Query/QueryRank";
import fieldPeerComparison from "../company/component/field/fieldPeerComparison.json";

const AllMarket = () => {
  const { t } = useTranslation("global");
  const [tooltipOpenPeer, setTooltipOpenPeer] = useState({});

  const [typeSelected, setTypeSelected] = useState({
    type: useLocation()?.state?.type,
    slug: useLocation()?.state?.slug,
  });
  const [sortBy, setSortBy] = useState({});
  const [markets, setMarkets] = useState([]);
  const [field] = useState(fieldPeerComparison);
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [countData, setCountData] = useState(0);

  const breadcrumbItems = [
    { label: "Market", path: "/market" },
    {
      label: typeSelected?.slug,
    },
  ];

  const [types] = useState([
    {
      type: "GAINER_RANK",
      slug: t("market.top_grainer"),
    },
    {
      type: "STOCK_RANK",
      slug: t("market.stock_rank"),
    },
    {
      type: "FREQUENT_RANK",
      slug: t("market.top_frequency"),
    },
    {
      type: "MARKET_CAPITAL",
      slug: t("market.marketCapitalization"),
    },
    {
      type: "VOLUME_RANK",
      slug: t("market.volume_stocker"),
    },
    {
      type: "VOLUME_ONE_WEEK_AVERAGE",
      slug: t("market.volume1WeekAverage"),
    },
    {
      type: "VOLUME_ONE_MONTH_AVERAGE",
      slug: t("market.volume1MonthAverage"),
    },
    {
      type: "RETURN_OVER_ONE_WEEK",
      slug: t("market.returnOver1Week"),
    },
    {
      type: "RETURN_OVER_ONE_MONTH",
      slug: t("market.returnOver1Month"),
    },
    {
      type: "RETURN_OVER_THREE_MONTH",
      slug: t("market.returnOver3Months"),
    },
    {
      type: "RETURN_OVER_SIX_MONTH",
      slug: t("market.returnOver6Months"),
    },
  ]);

  const [fetchMarket, { loading }] = useLazyQuery(QueryRank, {
    onCompleted: (data_) => {
      setCountData(data_?.rank?.count);
      setMarkets(data_?.rank?.data);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const toggleTooltip = (slug) => {
    setTooltipOpenPeer((prevState) => ({
      ...prevState,
      [slug]: !prevState[slug],
    }));
  };

  useEffect(() => {
    fetchMarket({
      variables: { type: typeSelected?.type, page: 1, limit: 50 },
    });
    // eslint-disable-next-line
  }, []);

  const handlePageClick = (page) => {
    setPagination({ ...pagination, page: page });
    fetchMarket({
      variables: {
        type: typeSelected?.type,
        page: page,
        limit: pagination?.limit,
        sortBy: [sortBy],
      },
    });
  };

  const handleChangeType = (e) => {
    setTypeSelected({
      type: e.target.value,
      slug: types?.find((item) => item?.type === e.target.value)?.slug,
    });
    setPagination({ ...pagination, page: 1 });
    fetchMarket({
      variables: { type: e.target.value, page: 1, limit: pagination?.limit },
    });
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    setPagination({ ...pagination, page: 1 });
    fetchMarket({
      variables: {
        type: typeSelected?.type,
        page: 1,
        limit: 50,
        sortBy: [value],
      },
    });
  };
  return (
    <div>
      {loading && <CLoading />}
      <CBreadcrumb items={breadcrumbItems} currentPath={"/market/all"} />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <p className="font-large fw-semibold">
              MARKET {typeSelected?.slug?.toLocaleUpperCase()}
            </p>
            <FormGroup>
              <Label for="type" className="font-label fw-semibold">
                Change Type
              </Label>
              <Input
                type="select"
                name="select"
                id="type"
                className="font-label shadow-none"
                value={typeSelected?.type}
                onChange={handleChangeType}
              >
                {types?.map((item) => (
                  <option key={item?.type} value={item?.type}>
                    {item?.slug?.toLocaleUpperCase()}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
          {field?.map((item) => (
            <Tooltip
              key={item?.slug}
              placement="top"
              isOpen={tooltipOpenPeer[item.slug] || false}
              target={item?.slug}
              toggle={() => toggleTooltip(item.slug)}
            >
              {t(`detail.${item.slug}`)}
            </Tooltip>
          ))}
          <CTableSector
            t={t}
            state={state}
            field={field}
            data={markets}
            pagination={pagination}
            countData={countData}
            onClickNavigate={(item) => navigate(`/company/${item?.code}`)}
            handlePageClick={handlePageClick}
            handleSortBy={handleSortBy}
            sortBy={sortBy}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default AllMarket;
