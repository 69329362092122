import moment from "moment";
import React, { useState } from "react";
import { TiAttachment } from "react-icons/ti";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import CInput from "../../../../components/CInput";

const SectionDocument = ({ announcements, annualReports, onFetchDocument }) => {
  const [search, setSearch] = useState("");
  return (
    <Card className="mt-3">
      <CardBody>
        <div className="mb-2">
          <span className="font-extra-large fw-bold">Documents</span>
        </div>
        <Row>
          <Col sm="12" md="12" lg="6">
            <div className="border p-2 rounded">
              <div>
                <span className="font-title fw-bold">Announcements</span>
              </div>
              <div className="py-2 d-flex flex-row gap-2">
                <CInput
                  heightInput={"40px"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={"Search Announcements"}
                  widthInput={"300px"}
                  borderColor={"border-primary"}
                />
                <Button
                  color="primary "
                  onClick={() => onFetchDocument(search)}
                >
                  Search
                </Button>
              </div>
              <div>
                {announcements?.map((item, index) => {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item?.attachments[0]?.fileUrl}
                      className="py-2 text-decoration-none"
                      key={index}
                      style={{
                        borderBottom: "1px solid #e5e5e5",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex flex-column py-1">
                        <span className="font-title ">
                          {item?.title} -{" "}
                          {moment(item?.dateCreated).format("DD MMM YYYY")}
                        </span>
                        <div className="font-description text-secondary">
                          <TiAttachment width={20} height={20} />{" "}
                          {item?.attachments[0]?.originalFileName}
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col sm="12" md="12" lg="6">
            <div className="border p-2 rounded h-100">
              <div>
                <span className="font-title fw-bold">Annual Reports</span>
              </div>
              <div>
                {annualReports?.map((item, index) => {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item?.filePath}
                      className="py-2 text-decoration-none"
                      key={index}
                      style={{
                        borderBottom: "1px solid #e5e5e5",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex flex-column py-1">
                        <span className="font-title ">{item?.title}</span>
                        <span className="font-label text-secondary">
                          from idx
                        </span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SectionDocument;
