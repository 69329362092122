import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import { GlobalContext } from "../../contexts/GlobalContext";
import NavbarDesktop from "./attributes/NavbarDesktop";
import NavbarMobile from "./attributes/NavbarMobile";

const Header = () => {
  const { state, updateSection, settings } = useContext(GlobalContext);

  const [subMenu] = useState([
    {
      slug: "summary",
      label: state?.company?.name,
    },
    {
      slug: "chart",
      label: "Chart",
    },

    {
      slug: "peers",
      label: "Peers",
    },
    {
      slug: "quarters",
      label: "Quarters",
    },
    {
      slug: "profitLoss",
      label: "Profit & Loss",
    },
    {
      slug: "balanceSheet",
      label: "Balance Sheet",
    },
    {
      slug: "cashFlow",
      label: "Cash Flow",
    },
    {
      slug: "ratios",
      label: "Ratios",
    },
    {
      slug: "investors",
      label: "Investors",
    },
    {
      slug: "forecast",
      label: "Forecast",
    },
  ]);
  const sectionRefs = useRef({});

  const [token, setToken] = useState(
    secureLocalStorage.getItem("token") || null
  );
  const [user, setUser] = useState(
    JSON.parse(secureLocalStorage.getItem("user")) || null
  );

  const { t, i18n } = useTranslation("global");
  const languages = [
    { label: "🇮🇩 Indonesia", code: "id" },
    { label: "🇺🇸 English", code: "en" },
  ];

  const onChangeLanguage = async () => {
    const newLanguage = i18n.language === "en" ? "id" : "en";

    await localStorage.setItem("language", newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    setToken(secureLocalStorage.getItem("token"));
    setUser(JSON.parse(secureLocalStorage.getItem("user")));
    // eslint-disable-next-line
  }, []);

  const onLogout = () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");
    secureLocalStorage.removeItem("dashboard_arr");
    secureLocalStorage.removeItem("dashboard_obj");

    window.location.href = "/";
  };

  const scrollToSection = (sectionId) => {
    updateSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      const topPosition = element.getBoundingClientRect().top + window.scrollY;
      const offset = 120;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",

      threshold: 0.6,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          updateSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      observer.observe(section);
      sectionRefs.current[section.id] = section;
    });

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavbarMobile
        token={token}
        user={user}
        darkMode={state?.darkMode}
        companyState={state?.company}
        sectionActive={state?.sectionActive}
        languages={languages}
        onChangeLanguage={onChangeLanguage}
        t={t}
        i18n={i18n}
        onLogout={onLogout}
        scrollToSection={scrollToSection}
        subMenu={subMenu}
        settings={settings}
      />

      <NavbarDesktop
        token={token}
        user={user}
        darkMode={state?.darkMode}
        companyState={state?.company}
        sectionActive={state?.sectionActive}
        languages={languages}
        onChangeLanguage={onChangeLanguage}
        t={t}
        i18n={i18n}
        onLogout={onLogout}
        scrollToSection={scrollToSection}
        subMenu={subMenu}
        settings={settings}
      />
    </div>
  );
};

export default Header;
