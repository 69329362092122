import { request } from "graphql-request";
import GenerateQuery from "./GenerateQuery";

const QueryStock = async ({ sector, params, sortBy, limit, page, fields }) => {
  const endPoint = process.env.REACT_APP_API_URL;
  const filterParams = params
    .map(({ key, range }) => `{ key: "${key}", range: [${range}] }`)
    .join(", ");

  const query = GenerateQuery({
    sector,
    params: `[${filterParams}]`,
    sortBy: sortBy ? `[{ key: "${sortBy.key}", sort: ${sortBy.sort} }]` : `[]`,
    limit,
    page,
    fields,
  });

  try {
    const data = await request(endPoint, query);
    return {
      data: data,
      code: 200,
    };
  } catch (error) {
    return {
      data: [],
      code: 500,
    };
  }
};

export default QueryStock;
