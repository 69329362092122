import { useQuery } from "@apollo/client";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import secureLocalStorage from "react-secure-storage";
import CInput from "../../components/CInput";
import CListSearch from "../../components/CListSearch";
import CLoading from "../../components/CLoading";
import MetaHelmet from "../../components/MetaHelmet";
import QueryAnalize from "../../graphQL/Query/QueryAnalize";
import IsMobile from "../../utils/IsMobile";

const Home = () => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation("global");
  const mobileView = IsMobile();

  const [analyse, setAnalyse] = useState([]);
  const [companies, setCompanies] = useState(
    secureLocalStorage.getItem("company")
      ? JSON.parse(secureLocalStorage.getItem("company"))
      : []
  );
  const [companiesTemp, setCompaniesTemp] = useState(
    secureLocalStorage.getItem("company")
      ? JSON.parse(secureLocalStorage.getItem("company"))
      : []
  );

  const fetchCompany = async () => {
    const response = await axios.get(
      `https://storage.sambalado.xyz/redbel-history/company.json`
    );
    if (response?.data?.length > 0) {
      secureLocalStorage.setItem("company", JSON.stringify(response?.data));
      setCompanies(response?.data);
      setCompaniesTemp(response?.data);
    }
  };

  const { loading, refetch: refetchAnalyse } = useQuery(QueryAnalize, {
    onCompleted(data) {
      setAnalyse(data?.analyze);
    },
  });

  useEffect(() => {
    refetchAnalyse();
    fetchCompany();
    // eslint-disable-next-line
  }, []);

  const onSearchCompany = (value) => {
    setSearch(value);
    const newCompanies = companies.filter(
      (company) =>
        company.name.toLowerCase().includes(value.toLowerCase()) ||
        company.code.toLowerCase().includes(value.toLowerCase())
    );
    setCompaniesTemp(newCompanies);
  };

  const onClearSearch = async () => {
    await setSearch("");
    await setCompaniesTemp(companies);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{
        height: "70vh",
      }}
    >
      <MetaHelmet />
      {loading && <CLoading />}
      <div className="text-center">
        <p
          className="mb-0"
          style={{
            fontSize: mobileView ? "28px" : "50px",
            fontWeight: "bold",
          }}
        >
          Redbel
        </p>
        <p
          className="mb-4"
          style={{
            fontSize: mobileView ? "16px" : "18px",
            fontWeight: "400",
          }}
        >
          {t("home.description")}
        </p>
      </div>
      <div>
        <CInput
          value={search}
          onChange={(e) => {
            onSearchCompany(e.target.value);
          }}
          placeholder={t("home.search_company")}
          widthInput={mobileView ? "100%" : "618px"}
          heightInput={mobileView ? "40px" : "60px"}
          borderColor={"border-primary"}
          leftIcon={<FaSearch size={20} />}
          rightIcon={
            search?.length > 0 && <IoClose color="#0e3dea" size={20} />
          }
          rightAction={() => onClearSearch()}
        />
        {companiesTemp?.length > 0 && search?.length > 0 && (
          <CListSearch
            companies={companiesTemp}
            widthMobile={"250px"}
            widthDesktop={"618px"}
          />
        )}
      </div>

      <div
        className="text-left mt-3 d-flex flex-wrap justify-content-center align-items-center"
        style={{
          width: mobileView ? "100%" : "618px",
        }}
      >
        <div className="d-flex flex-row flex-wrap gap-2 justify-content-center align-items-center">
          <span style={{ fontSize: "16px", fontWeight: "500" }}>
            {t("home.or_analyze")}
          </span>
          {analyse?.map((item) => (
            <div
              className="border p-2 rounded card-search bg-primary text-light"
              style={{
                fontSize: mobileView ? "10px" : "14px",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = `/company/${item?.code}`)}
            >
              {item?.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
