import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CInput from "../../../components/CInput";
import CModalSide from "../../../components/CModalSide";
import { GlobalContext } from "../../../contexts/GlobalContext";
import IsMobile from "../../../utils/IsMobile";

const ModalAddRatio = ({ isOpen, setIsOpen, stockByUser, onSave }) => {
  const { stockScreener, state } = useContext(GlobalContext);
  const mobileView = IsMobile();
  const { t } = useTranslation("global");
  const [search, setSearch] = useState("");
  const [warning, setWarning] = useState(false);
  const filteredStock = stockScreener
    .filter(
      (item, index, self) => self.findIndex((t) => t.key === item.key) === index
    )
    .map((item_) => {
      return {
        key: item_?.key,
        label: item_?.displayName,
        range: [parseFloat(item_?.value?.min), parseFloat(item_?.value?.max)],
        origin_range: [
          parseFloat(item_?.value?.min),
          parseFloat(item_?.value?.max),
        ],
      };
    });

  const [stockScreenerTemp, setStockScreenerTemp] = useState(filteredStock);
  const [stockScreenerOriginal] = useState(filteredStock);
  const [stockByUserSelected, setStockByUserSelected] = useState(stockByUser);

  useEffect(() => {
    setStockScreenerTemp(filteredStock);
    setStockByUserSelected(stockByUser);
    // eslint-disable-next-line
  }, [isOpen]);

  const onSearch = (value) => {
    setSearch(value);
    const newParameters = stockScreenerOriginal.filter((parameter) =>
      t("summary." + parameter?.label)
        .toLowerCase()
        .includes(value?.toLowerCase())
    );
    setStockScreenerTemp(newParameters);
  };

  const onSelectParameter = (value) => {
    const stockByUsr = [...stockByUserSelected];
    if (stockByUsr.some((item) => item.key === value.key)) {
      setWarning(false);
      stockByUsr.splice(
        stockByUsr.findIndex((item) => item.key === value.key),
        1
      );
    } else {
      if (stockByUsr.length >= 10) {
        setWarning(true);
        return;
      }
      stockByUsr.push(value);
    }
    setStockByUserSelected(stockByUsr);
  };
  return (
    <CModalSide isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
      <ModalHeader>
        <div className="d-flex flex-row justify-content-between mb-2">
          <span className="fw-semiBold font-large ">Add More Stock</span>
          <div className="ms-2" onClick={() => setIsOpen(false)}>
            <IoCloseSharp size={25} />
          </div>
        </div>
        <div>
          <CInput
            value={search}
            leftIcon={<FaSearch size={14} />}
            widthInput={mobileView ? "100%" : "400px"}
            heightInput={"40px"}
            placeholder={"Search stock"}
            borderColor={"border-primary"}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
        {warning && (
          <div className="bg-danger px-3 rounded mt-2 d-flex align-items-center">
            <span className="fw-semiBold font-label text-white">
              You can only add 10 stocks
            </span>
          </div>
        )}
      </ModalHeader>
      <ModalBody
        style={{
          overflowY: "auto",
        }}
      >
        <div>
          {stockScreenerTemp?.length > 0 && (
            <div className="d-flex  justify-content-lg-end justify-content-sm-start justify-content-start gap-1">
              <div
                className={`position-absolute  p-2 border  ${
                  state?.darkMode ? "bg-secondary text-light" : "bg-light"
                } mt-1 rounded shadow-sm z-1`}
                style={{
                  overflowY: "auto",
                  width: mobileView ? "95%" : "400px",
                }}
              >
                {stockScreenerTemp.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => onSelectParameter(item)}
                    className="p-2  card-search border-bottom d-flex flex-row justify-content-between align-items-center"
                    style={{
                      fontSize: mobileView ? "10px" : "14px",
                      cursor: "pointer",
                    }}
                  >
                    <div className="d-flex flex-column">
                      <span className="me-3 font-label">
                        {t("summary." + item.label)}
                      </span>
                    </div>
                    {stockByUserSelected?.some(
                      (item_) => item_.key === item.key
                    ) && (
                      <div>
                        <BsCheck2 size={25} color="#007BFF" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          class="w-100 bg-primary py-2 text-center text-white rounded "
          style={{ cursor: "pointer" }}
          onClick={() => {
            onSave(stockByUserSelected);
          }}
        >
          Save
        </div>
      </ModalFooter>
    </CModalSide>
  );
};

export default ModalAddRatio;
