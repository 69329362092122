import { gql } from "@apollo/client";

const QueryRank = gql`
  query Rank(
    $type: TYPE
    $limit: Int
    $page: Int
    $sortBy: [OrderingStockScreenerParam]
  ) {
    rank(rankType: $type, limit: $limit, page: $page, sortBy: $sortBy) {
      page
      limit
      count
      data {
        ... on CompanySchema {
          name
          code
          logo
          stockSummaries {
            price
            change
            volume
            volumeRank
            stockRank
            gainerRank
            frequentRank
            tradeFrequency
            volume1WeekAverage
            volume1MonthAverage
            returnOver1Week
            returnOver1Month
            returnOver3Months
            returnOver6Months
            priceToEarning
            marketCapitalization
            dividendYield
            netProfitLatestQuarter
            yoyQuarterlyProfitGrowth
            salesLatestQuarter
            yoyQuarterlySalesGrowth
            returnOnCapitalEmployed
          }
        }
      }
    }
  }
`;

export default QueryRank;
