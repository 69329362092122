import React, { useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Layout from "../layouts/Layout";
import ScrollToTop from "../utils/scrollToTop";
import ForgotPassword from "../views/auth/forgot_password/ForgotPassword";
import ForgotPasswordDone from "../views/auth/forgot_password/ForgotPasswordDone";
import Login from "../views/auth/login/Login";
import Register from "../views/auth/register/Register";
import VerifyAccount from "../views/auth/register/VerifyAccount";
import ResetPassword from "../views/auth/reset_password/ResetPassword";
import Company from "../views/company/Company";
import Detail from "../views/detail/Detail";
import Home from "../views/home/Home";
import ManageRatio from "../views/manage-ratio/ManageRatio";
import AllMarket from "../views/market/AllMarket";
import Market from "../views/market/Market";
import DetailNews from "../views/news/DetailNews";
import News from "../views/news/News";
import Profile from "../views/profile/Profile";
import CompareSector from "../views/screen/CompareSector";
import Screens from "../views/screen/Screens";
import AboutUs from "../views/settings/AboutUs";
import Privacy from "../views/settings/Privacy";
import Term from "../views/settings/Term";

const ProtectedRoute = ({ element }) => {
  const token = secureLocalStorage.getItem("token");
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return element;
};

const AppRouter = () => {
  const [token] = useState(secureLocalStorage.getItem("token") || null);

  return (
    <Router>
      <Layout>
        <ScrollToTop />
        <Routes>
          {token ? (
            <>
              <Route path="/" element={<Market />} />
            </>
          ) : (
            <Route path="/" element={<Home />} />
          )}

          <Route path="/detail" element={<Detail />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/market" element={<Market />} />
          <Route path="/market/all" element={<AllMarket />} />
          <Route path="/screen" element={<Screens />} />
          <Route path="/sector/compare/:name" element={<CompareSector />} />
          <Route path="/company/:companyId" element={<Company />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/verify" element={<VerifyAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/done"
            element={<ForgotPasswordDone />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<DetailNews />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-and-conditions" element={<Term />} />
          <Route path="/privacy-policy" element={<Privacy />} />

          {/* Must Login */}
          <Route
            path="/manage-ratio/:companyId"
            element={<ProtectedRoute element={<ManageRatio />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
