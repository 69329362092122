import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaArrowAltCircleUp,
} from "react-icons/fa";
import { RiResetLeftFill } from "react-icons/ri";
import { GlobalContext } from "../../../../contexts/GlobalContext";

const SectionChartCompare = ({ optionCompare }) => {
  const { state } = useContext(GlobalContext);
  const chartRef = useRef(null);
  const [optionChart, setOptionChart] = useState({
    chart: {
      zoomType: "xy",
      panning: true,
      panKey: null,
      backgroundColor: state.darkMode ? "#212531" : "#ffffff",
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {},
    yAxis: {},
    credits: {
      enabled: false,
    },
    series: [],
    legend: {
      enabled: true,
    },
  });

  useEffect(() => {
    setOptionChart(optionCompare);
    // eslint-disable-next-line
  }, [optionCompare]);

  useEffect(() => {
    setOptionChart({
      ...optionChart,
      chart: {
        ...optionChart.chart,
        backgroundColor: state.darkMode ? "#212531" : "#ffffff",
      },
      xAxis: {
        ...optionChart.xAxis,
        labels: {
          style: {
            color: state.darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      yAxis: {
        ...optionChart.yAxis,
        labels: {
          style: {
            color: state.darkMode ? "#ffffff" : "#000000",
          },
        },
      },
      legend: {
        ...optionChart.legend,
        itemStyle: {
          color: state.darkMode ? "#ffffff" : "#000000",
        },
      },
    });

    // eslint-disable-next-line
  }, [state.darkMode]);

  const handleZoomIn = () => {
    const chart = chartRef.current.chart;
    const yAxis = chart.yAxis[0];
    const extremes = yAxis.getExtremes();
    const range = (extremes.max - extremes.min) / 2;
    yAxis.setExtremes(extremes.min + range / 2, extremes.max - range / 2);
  };

  const handleZoomOut = () => {
    const chart = chartRef.current.chart;
    const yAxis = chart.yAxis[0];
    const extremes = yAxis.getExtremes();
    const range = (extremes.max - extremes.min) * 2;
    yAxis.setExtremes(extremes.min - range / 4, extremes.max + range / 4);
  };

  const handleResetZoom = () => {
    const chart = chartRef.current.chart;
    chart.zoomOut(); // Reset semua zoom
  };

  const panChart = (direction) => {
    const chart = chartRef.current.chart;
    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];

    if (direction === "left" || direction === "right") {
      const xExtremes = xAxis.getExtremes();
      const xPanStep = (xExtremes.max - xExtremes.min) * 0.2; // 20% langkah geser
      if (direction === "left") {
        xAxis.setExtremes(xExtremes.min - xPanStep, xExtremes.max - xPanStep);
      } else if (direction === "right") {
        xAxis.setExtremes(xExtremes.min + xPanStep, xExtremes.max + xPanStep);
      }
    }

    if (direction === "up" || direction === "down") {
      const yExtremes = yAxis.getExtremes();
      const yPanStep = (yExtremes.max - yExtremes.min) * 0.2; // 20% langkah geser
      if (direction === "up") {
        yAxis.setExtremes(yExtremes.min + yPanStep, yExtremes.max + yPanStep);
      } else if (direction === "down") {
        yAxis.setExtremes(yExtremes.min - yPanStep, yExtremes.max - yPanStep);
      }
    }
  };

  return (
    <div className="mt-2">
      <div className="d-flex flex-row justify-content-end align-items-center gap-3">
        <div>
          <button className="btn btn-sm btn-primary" onClick={handleZoomIn}>
            <BsZoomIn size={20} />
          </button>
          <button
            className="btn btn-sm btn-primary mx-2"
            onClick={handleZoomOut}
          >
            <BsZoomOut size={20} />
          </button>
          <button className="btn btn-sm btn-primary" onClick={handleResetZoom}>
            <RiResetLeftFill size={20} />
          </button>
        </div>
        <div className="pan-buttons">
          <div className="pan-button-container">
            <div className="d-flex justify-content-center">
              <div
                className=" up d-flex align-items-center"
                onClick={() => panChart("up")}
              >
                <FaArrowAltCircleUp size={25} color="#0078ff" />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="left" onClick={() => panChart("left")}>
                <FaArrowAltCircleLeft size={25} color="#0078ff" />
              </div>
              <div className=" right" onClick={() => panChart("right")}>
                <FaArrowAltCircleRight size={25} color="#0078ff" />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="down" onClick={() => panChart("down")}>
                <FaArrowAltCircleDown size={25} color="#0078ff" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={optionChart}
        ref={chartRef}
      />
    </div>
  );
};

export default SectionChartCompare;
