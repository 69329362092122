import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardBody, Table } from "reactstrap";
import { isCurrencyWithOutRp } from "../../../../utils/isCurrency";

const SectionShareholding = ({
  dataSetShareHoldingPattern,
  dataOptionsShareHoldingPattern,
  shareHoldingPattern,
  t,
  darkMode,
}) => {
  const [field] = useState(["name", "percentage", "stock"]);
  return (
    <Card className="mt-4 shadow-sm">
      <CardBody>
        <div>
          <span className="font-extra-large fw-bold">
            {t("detail.shareholding")}
          </span>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
          <div className="col-sm-12 col-lg-6 col-12 col-md-12">
            <Pie
              data={dataSetShareHoldingPattern}
              options={dataOptionsShareHoldingPattern}
            />
          </div>
          <div className="col-sm-12 col-lg-6 col-12 col-md-12">
            <div className="table-container">
              <Table className={`detail-table ${darkMode && "table-dark"}`}>
                <thead>
                  <tr className="quarter-tr">
                    {field?.map((item, index) => (
                      <th
                        key={index}
                        className="font-label fw-bold bg-primary text-light"
                      >
                        {t(`detail.${item}`)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {shareHoldingPattern?.map((item, index) => (
                    <tr
                      key={index}
                      className={`font-description ${
                        index % 2 === 0 ? "bg-brown text-dark" : ""
                      }`}
                    >
                      <td
                        className={`font-description ${
                          index % 2 === 0 ? "bg-brown text-dark" : ""
                        }`}
                      >
                        {item?.name}
                      </td>
                      <td
                        className={`font-description ${
                          index % 2 === 0 ? "bg-brown text-dark" : ""
                        }`}
                      >
                        {item?.percentage} %
                      </td>
                      <td
                        className={`font-description ${
                          index % 2 === 0 ? "bg-brown text-dark" : ""
                        }`}
                      >
                        {isCurrencyWithOutRp(item?.totalStock)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionShareholding;
