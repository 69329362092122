export function isCurrency(nominal) {
  let positif_nominal = Math.abs(nominal) || 0;
  let isMinus = nominal < 0 ? true : false;

  const formatNumber = (num) => {
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)} T`; // Trillion
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)} B`; // Billion
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)} M`; // Million
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)} K`; // Thousand
    }
    return num.toLocaleString("id-ID"); // Smaller numbers
  };

  const formattedValue = formatNumber(positif_nominal);

  return (
    <span>
      Rp {isMinus && "-"}
      {formattedValue}
    </span>
  );
}

export function isCurrencyWithOutRp(nominal) {
  nominal = nominal || 0;

  const formatNumber = (num) => {
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)} T`; // Trillion
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)} B`; // Billion
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)} M`; // Million
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)} K`; // Thousand
    }
    return num.toLocaleString("id-ID");
  };

  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);

  let value = price.substring(0, price.length - 3).split("p")[1];

  return (
    <span>
      {price.includes("-") ? "-" : null}
      {formatNumber(Number(value.replace(/\./g, "")))}
    </span>
  );
}

export function isCurrencyNumber(nominal) {
  nominal = nominal ? nominal : 0;
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  let value = price.substring(0, price.length - 3).split("p")[1];
  return value;
}

export function isCurrencyWithOutRpFormat(nominal) {
  nominal = nominal || 0;

  const formatNumber = (num) => {
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)} T`; // Trillion
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)} B`; // Billion
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)} M`; // Million
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)} K`; // Thousand
    }
    return num.toLocaleString("id-ID");
  };

  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);

  let value = price.substring(0, price.length - 3).split("p")[1];

  return formatNumber(Number(value.replace(/\./g, "")));
}

export function isCurrencyWithOutDecimal(nominal) {
  nominal = nominal || 0;
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(nominal);

  return price;
}

export function isCurrencyWithOutRpAndWithOutFormat(nominal) {
  const value = Number(nominal);

  if (isNaN(value)) {
    return <span>0</span>; // Jika tidak valid, tampilkan 0
  }

  const formatter = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });

  // Format nilai sesuai kondisi
  const formattedValue =
    value % 1 !== 0 ? formatter.format(value) : value.toLocaleString("id-ID");

  return (
    <span>
      {value < 0 && !formattedValue.includes("-") ? "-" : null}
      {formattedValue}
    </span>
  );
}
