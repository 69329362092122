import { Box, Button, Slider, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

const CMinMaxSlider = ({
  slug,
  label,
  origin_value,
  value,
  handleChange,
  onClose,
}) => {
  const [valueTemp, setValueTemp] = useState(value);
  const { state } = useContext(GlobalContext);
  const handleSliderChange = (event, newValue) => {
    setValueTemp(newValue.map((val) => val || 0));
  };

  const handleInputChange = (event, index) => {
    const inputValue = event.target.value || 0;
    const newValue = [...value];

    if (index === 0) {
      newValue[0] = Math.min(inputValue, newValue[1]);
    } else if (index === 1) {
      // Validasi Max
      newValue[1] = Math.max(inputValue, newValue[0]);
    }

    setValueTemp(newValue);
  };

  return (
    <Box sx={{ padding: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
      <Box
        onClick={onClose}
        className="bg-primary"
        sx={{
          color: "#fff",
          padding: "8px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <div className="font-label">{label}</div>
        <div className="font-small">
          Min {valueTemp[0]}, Max {valueTemp[1]}
        </div>
      </Box>
      <Box sx={{ marginTop: 2, display: "flex", alignItems: "center", gap: 2 }}>
        <TextField
          label="Min"
          type="text"
          value={valueTemp[0]}
          onChange={(e) => {
            if (!/^[0-9]$/.test(e)) {
              handleInputChange(e, 0);
            }
          }}
          // onBlur={() => handleBlur(1, origin_value[0], origin_value[1])}
          size="small"
          inputProps={{
            style: { fontSize: 12, color: state.darkMode ? "#fff" : "#000" },
          }}
          sx={{
            maxWidth: "80px",
            "& .MuiInputBase-input": {
              color: state.darkMode ? "#fff" : "#000",
            },
            "& .MuiOutlinedInput-root": {
              backgroundColor: state.darkMode ? "#333" : "#fff",
              "& fieldset": {
                borderColor: state.darkMode ? "#555" : "#ccc",
              },
              "&:hover fieldset": {
                borderColor: state.darkMode ? "#bbb" : "#000",
              },
              "&.Mui-focused fieldset": {
                borderColor: state.darkMode ? "#fff" : "#1976d2",
              },
            },
            "& .MuiFormLabel-root": {
              color: state.darkMode ? "#aaa" : "#000",
            },
          }}
        />
        <Slider
          value={valueTemp}
          min={origin_value[0]}
          max={origin_value[1]}
          step={0.01}
          size="small"
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          sx={{ flex: 1, color: "#0078ff", width: "100px" }}
        />
        <TextField
          label="Max"
          type="text"
          value={valueTemp[1]}
          inputProps={{
            style: {
              fontSize: 12,
              color: state.darkMode ? "#fff" : "#000",
            },
          }}
          onChange={(e) => {
            if (!/^[0-9]$/.test(e)) {
              handleInputChange(e, 1);
            }
          }}
          size="small"
          sx={{
            maxWidth: "80px",
            "& .MuiInputBase-input": {
              color: state.darkMode ? "#fff" : "#000", // Warna teks input
            },
            "& .MuiOutlinedInput-root": {
              backgroundColor: state.darkMode ? "#333" : "#fff", // Warna latar input
              "& fieldset": {
                borderColor: state.darkMode ? "#555" : "#ccc", // Warna border default
              },
              "&:hover fieldset": {
                borderColor: state.darkMode ? "#bbb" : "#000", // Warna border saat hover
              },
              "&.Mui-focused fieldset": {
                borderColor: state.darkMode ? "#fff" : "#1976d2", // Warna border saat fokus
              },
            },
            "& .MuiFormLabel-root": {
              color: state.darkMode ? "#aaa" : "#000", // Warna label
            },
          }}
        />
        <Button
          variant="contained"
          className="bg-primary"
          onClick={() => handleChange(slug, valueTemp)}
          sx={{ color: "#fff" }}
        >
          Set
        </Button>
      </Box>
    </Box>
  );
};

export default CMinMaxSlider;
