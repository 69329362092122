import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { BsArrowRightCircle } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import CLoading from "../../components/CLoading";
import CMinMaxSlider from "../../components/CMinMaxSlider";
import { GlobalContext } from "../../contexts/GlobalContext";
import Sectors from "../../graphQL/Query/Sectors";
import ModalAddRatio from "./components/ModalAddRatio";
import StockTable from "./components/StockTable";
import defaultStock from "./field/defaultStock.json";
import QueryStock from "./service/QueryStock";

const Screens = () => {
  const { t } = useTranslation("global");
  const { state, stockScreener } = useContext(GlobalContext);
  const [sectors, setSectors] = useState([]);
  const [stockByUser, setStockByUser] = useState([]);
  const [stockOpen, setStockOpen] = useState(null);
  const [searchSector, setSearchSector] = useState("");
  const [params, setParams] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [sectorSelected, setSectorSelected] = useState("");
  const [stocks, setStocks] = useState([]);
  const [countData, setCountData] = useState(0);
  const [modalAddRatio, setModalAddRatio] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();
  const alert = useAlert();

  const [fieldTable, setFieldTable] = useState([
    {
      key: "name",
      value: "companyName",
    },

    {
      key: "code",
      value: "companyCode",
    },
    {
      key: "primarySector",
      value: "primarySector",
    },
    {
      key: "subSector",
      value: "subSector",
    },
    {
      key: "industry",
      value: "industry",
    },
    {
      key: "subIndustry",
      value: "subIndustry",
    },
    {
      key: "book_value",
      value: "bookValue",
    },
    {
      key: "high_price",
      value: "highPrice",
    },
    {
      key: "low_price",
      value: "lowPrice",
    },

    {
      key: "market_capitalization",
      value: "marketCapitalization",
    },
    {
      key: "price",
      value: "price",
    },
    {
      key: "return_on_capital_employed",
      value: "returnOnCapitalEmployed",
    },

    {
      key: "return_on_equity",
      value: "returnOnEquity",
    },
  ]);

  useQuery(Sectors, {
    onCompleted: (data) => {
      setSectors(data?.sector);
    },
  });

  useEffect(() => {
    onSettingStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitFilter = async () => {
    const stock = [...stockByUser];

    const paramsSubmit = stock.map((item) => {
      return {
        key: item?.key,
        displayName: item?.label,
        range: item?.range.map((item) => parseFloat(item)),
      };
    });
    setParams(paramsSubmit);
    setLoading(true);
    let response = await QueryStock({
      sector: sectorSelected,
      params: paramsSubmit,
      sortBy: orderBy,
      limit: pagination.limit,
      page: pagination.page,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);

      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const onSelectSector = async (sector) => {
    setSectorSelected(sector);
    setPagination({ ...pagination, page: 1 });
    setLoading(true);
    let response = await QueryStock({
      sector: sector,
      params: params,
      sortBy: orderBy,
      limit: pagination.limit,
      page: 1,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);
      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const handlePageClick = async (page) => {
    setPagination({ ...pagination, page: page });
    setLoading(true);
    let response = await QueryStock({
      sector: sectorSelected,
      params: params,
      sortBy: orderBy,
      limit: pagination.limit,
      page: page,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);
      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const onSettingStock = async () => {
    let stock = stockScreener || [];
    let userStock = defaultStock || [];

    const filteredStock = stock
      .filter((item) => userStock.some((userItem) => userItem.key === item.key))
      .filter(
        (item, index, self) =>
          self.findIndex((t) => t.key === item.key) === index
      )
      .map((item_) => {
        return {
          key: item_?.key,
          label: item_?.displayName,
          range: [parseFloat(item_?.value?.min), parseFloat(item_?.value?.max)],
          origin_range: [
            parseFloat(item_?.value?.min),
            parseFloat(item_?.value?.max),
          ],
        };
      });
    const params = filteredStock.map((item) => {
      return {
        key: item?.key,
        displayName: item?.label,
        range: item?.range.map((item) => item),
      };
    });
    await setParams(params);
    await setStockByUser(filteredStock);
    setLoading(true);
    let response = await QueryStock({
      sector: sectorSelected,
      params: params,
      sortBy: orderBy,
      limit: pagination.limit,
      page: pagination.page,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);
      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const onAddStock = async (item) => {
    setModalAddRatio(false);
    setStockByUser(item);
    const params = item.map((item) => {
      return {
        key: item?.key,
        displayName: item?.label,
        range: item?.range.map((item) => item),
      };
    });
    await setParams(params);
    const newField = item.map((item) => {
      return {
        key: item?.key,
        value: item?.label,
      };
    });
    await setFieldTable([...fieldTable, ...newField]);
    setLoading(true);
    let response = await QueryStock({
      sector: sectorSelected,
      params: params,
      sortBy: orderBy,
      limit: pagination.limit,
      page: pagination.page,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);
      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const handleSortBy = async (value) => {
    setOrderBy(value);
    setPagination({ ...pagination, page: 1 });
    setLoading(true);
    let response = await QueryStock({
      sector: sectorSelected,
      params: params,
      sortBy: value,
      limit: pagination.limit,
      page: 1,
      fields: params.map((item) => item?.displayName),
    });
    setLoading(false);
    if (response?.code === 200) {
      setCountData(response?.data?.stockScreener?.count);
      setStocks(response?.data?.stockScreener?.data);
    }
  };

  const handleSliderChange = async (key, range) => {
    let stock = [...stockByUser];
    stock = stock.map((item) => {
      if (item.key === key) {
        item.range = range;
      }
      return item;
    });

    await setStockByUser(stock);
    await setStockOpen(null);
  };

  return (
    <div>
      {loading && <CLoading />}
      <ModalAddRatio
        isOpen={modalAddRatio}
        setIsOpen={setModalAddRatio}
        stockByUser={stockByUser}
        onSave={(e) => onAddStock(e)}
      />
      <Row>
        <Col sm="12" lg="12" md="12">
          <div>
            <p className="font-extra-large fw-bold">Stock Screener</p>
          </div>
          <Card>
            <CardHeader className="d-flex flex-row justify-content-between align-items-center bg-light ">
              <div className="d-flex justify-content-end">
                <span className="font-label fw-semibold">Kriteria Pilihan</span>
              </div>
              <div className="mt-2">
                <Autocomplete
                  freeSolo
                  options={sectors}
                  value={searchSector}
                  onChange={(event, newValue) => onSelectSector(newValue || "")}
                  inputValue={searchSector || ""}
                  onInputChange={(event, newInputValue) =>
                    setSearchSector(newInputValue)
                  }
                  style={{
                    width: 300,
                    backgroundColor: state?.darkMode ? "#212531" : "#fff",
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Sector"
                      onKeyDown={(event) => {}}
                      variant="outlined"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-input": {
                          color: state.darkMode ? "#fff" : "#000", // Warna teks input
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: state.darkMode ? "#333" : "#fff", // Warna latar input
                          "& fieldset": {
                            borderColor: state.darkMode ? "#555" : "#ccc", // Warna border default
                          },
                          "&:hover fieldset": {
                            borderColor: state.darkMode ? "#bbb" : "#000", // Warna border saat hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: state.darkMode ? "#fff" : "#1976d2", // Warna border saat fokus
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: state.darkMode ? "#aaa" : "#000", // Warna label
                        },
                      }}
                    />
                  )}
                />
              </div>
            </CardHeader>
            <CardBody>
              <div className="d-flex flex-row flex-wrap gap-1 justify-content-start ">
                {stockByUser?.map((item, index) => (
                  <div>
                    {item?.key !== stockOpen?.key && (
                      <div
                        onClick={() =>
                          item?.key === stockOpen?.key
                            ? setStockOpen(null)
                            : setStockOpen(item)
                        }
                        key={index}
                        style={{ cursor: "pointer" }}
                        className={`border px-2 py-1 font-label rounded  d-flex align-items-center gap-2 ${
                          state?.darkMode
                            ? "bg-darkMode text-light"
                            : "bg-brown text-dark"
                        }`}
                      >
                        {t("summary." + item?.label)}
                        <div>
                          <CiCirclePlus size={20} />
                        </div>
                      </div>
                    )}

                    {stockOpen?.key === item?.key && (
                      <CMinMaxSlider
                        onClose={() => setStockOpen(null)}
                        slug={item?.key}
                        label={t("summary." + item?.label)}
                        origin_value={item?.origin_range}
                        value={item?.range}
                        handleChange={(slug, value) =>
                          handleSliderChange(slug, value)
                        }
                      />
                    )}
                  </div>
                ))}
                {stockOpen === null && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalAddRatio(true)}
                    className={`border px-2 py-1 font-label rounded  d-flex align-items-center gap-2 ${"bg-success text-white"}`}
                  >
                    {t("Add More Ratio")}
                    <div>
                      <BsArrowRightCircle size={20} />
                    </div>
                  </div>
                )}
              </div>
            </CardBody>
            <CardFooter className="d-flex justify-content-end align-items-center bg-light">
              <Button color="primary" size="sm" onClick={onSubmitFilter}>
                Apply
              </Button>
            </CardFooter>
          </Card>
          <Card className="mt-4">
            <CardHeader></CardHeader>
            <CardBody>
              <StockTable
                t={t}
                state={state}
                fieldTable={fieldTable}
                data={stocks}
                pagination={pagination}
                countData={countData}
                handlePageClick={handlePageClick}
                sortBy={orderBy}
                handleSortBy={handleSortBy}
              />
            </CardBody>
          </Card>
        </Col>
        {/* <Col sm="12" lg="4" md="4">
          <Card>
            <CardBody>
              <div>
                <p className="font-large fw-bold">
                  {t("screen.browse_sector")}
                </p>
              </div>
              <div className="d-flex flex-row flex-wrap gap-3 justify-content-start align-items-center">
                {sectors?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      navigate(`/sector/compare/${item.toLowerCase()}`, {
                        state: { sector: item.toLowerCase() },
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className={`border p-1 font-label rounded card-search ${
                      state?.darkMode
                        ? "bg-darkMode text-light"
                        : "bg-brown text-dark"
                    }`}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default Screens;
