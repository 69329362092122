import { useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormFeedback,
  Input,
  Spinner,
} from "reactstrap";
import CAvatar from "../../components/CAvatar";
import CInput from "../../components/CInput";
import ChangePassword from "../../graphQL/Mutation/ChangePassword";
import UpdateProfile from "../../graphQL/Mutation/UpdateProfile";
import QueryProfile from "../../graphQL/Query/QueryProfile";
import IsMobile from "../../utils/IsMobile";
import formValidation from "../../utils/formValidation";

const Profile = () => {
  const alert = useAlert();
  const { t } = useTranslation("global");
  const [user, setUser] = useState(null);
  const [token] = useState(secureLocalStorage.getItem("token") || null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const mobileView = IsMobile();
  const [isEdit, setIsEdit] = useState(null);
  const [data, setData] = useState({
    file: null,
    dob: "",
    gender: "",
  });

  const [error, setError] = useState({
    dob: false,
    gender: false,
  });

  const [dataPassword, setDataPassword] = useState({
    oldPassword: "",
    password: "",
  });
  const [errorPassword, setErrorPassword] = useState({
    oldPassword: false,
    password: false,
  });

  const [fetchProfile] = useLazyQuery(QueryProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    onCompleted: (data) => {
      setUser(data?.profile);
      setData({
        file: data?.profile?.userProfiles?.imageUrl,
        dob: data?.profile?.userProfiles?.dob,
        gender: data?.profile?.userProfiles?.gender,
      });
      setPreviewUrl(data?.profile?.userProfiles?.imageUrl);

      secureLocalStorage.setItem("user", JSON.stringify(data?.profile));
    },
  });

  const [updateProfile, { loading }] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    onCompleted: (data) => {
      alert.success("Success update profile");
      setData({
        file: data?.updateProfile?.imageUrl,
        dob: data?.updateProfile?.dob,
        gender: data?.updateProfile?.gender,
      });
      let userInfo = JSON.parse(secureLocalStorage.getItem("user"));
      userInfo.userProfiles.dob = data?.updateProfile?.dob;
      userInfo.userProfiles.gender = data?.updateProfile?.gender;
      userInfo.userProfiles.imageUrl = data?.updateProfile?.imageUrl;
      secureLocalStorage.setItem("user", JSON.stringify(userInfo));
      setUser({
        ...user,
        userProfiles: {
          ...user.userProfiles,
          dob: data?.updateProfile?.dob,
          gender: data?.updateProfile?.gender,
          imageUrl: data?.updateProfile?.imageUrl,
        },
      });
    },

    onError: ({ networkError, graphQLErrors }) => {
      alert.error("Failed update profile");
    },
  });

  const [changePassword, { loading: loadingChange }] = useMutation(
    ChangePassword,
    {
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      onCompleted: (data) => {
        if (data?.changePassword?.status) {
          setIsEdit(null);

          alert.success("Success change password");
        } else {
          alert.error(data?.changePassword?.error);
        }
      },
      onError: ({ networkError, graphQLErrors }) => {
        alert.error("Failed change password");
      },
    }
  );

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line
  }, []);

  const onHandleChange = async (name, value) => {
    let validate = true;
    if (name !== "file") {
      validate = await formValidation(name, value);
    } else {
      setPreviewUrl(URL.createObjectURL(value));
    }

    setError({
      ...error,
      [name]: !validate,
    });
    setData({
      ...data,
      [name]: value,
    });
  };

  console.log("data", data);

  const onHandleChangePassword = async (name, value) => {
    let validate = true;
    if (name === "password" && value?.length < 8) {
      validate = false;
    } else {
      validate = await formValidation(name, value);
    }

    setErrorPassword({
      ...errorPassword,
      [name]: !validate,
    });
    setDataPassword({
      ...dataPassword,
      [name]: value,
    });
  };

  const getGender = (gender) => {
    if (gender === "MALE") {
      return "Laki-laki";
    } else if (gender === "FEMALE") {
      return "Perempuan";
    }
  };

  const onSubmit = async () => {
    const newData = { ...data };
    delete newData.file;
    let errors = 0;
    for (let i in newData) {
      const name = i;
      const value = newData[i];
      const validate = await formValidation(name, value);
      if (!validate) {
        errors = errors + 1;
      }
      setError((prevState) => ({
        ...prevState,
        [name]: !validate,
      }));
    }
    if (errors > 0) {
      alert.error("Please fill all fields");
      return;
    }
    if (data?.file) {
      const formData = new FormData();
      formData.append(
        "operations",
        JSON.stringify({
          query: `mutation($file: Upload!) {
          uploadFile(file: $file) {
            fileUrl
          }
        }`,
          variables: { file: null },
        })
      );
      formData.append("map", JSON.stringify({ file: ["variables.file"] }));
      formData.append("file", data?.file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.errors) {
          alert.error("Failed upload file");
          return;
        }

        updateProfile({
          variables: {
            dob: data.dob,
            gender: data.gender,
            imageUrl: response.data.data.uploadFile.fileUrl,
          },
        });
      } catch (error) {
        alert.error("Failed upload file");
      }
    } else {
      updateProfile({
        variables: {
          dob: data.dob,
          gender: data.gender,
        },
      });
    }
    setIsEdit(null);
  };

  const onSubmitChangePassword = async () => {
    const newData = { ...dataPassword };

    if (!newData.oldPassword || !newData.password) {
      alert.error("Please fill all fields");
      return;
    }
    if (newData.password?.length < 8) {
      alert.error("Password must be at least 8 characters");
      return;
    }

    changePassword({
      variables: {
        oldPassword: dataPassword.oldPassword,
        password: dataPassword.password,
      },
    });
  };

  return (
    <div className="d-flex align-items-center flex-column">
      <Card className={mobileView ? "w-100 mb-3" : "w-50 mb-3"}>
        <CardBody>
          <div className="d-flex flex-row gap-3">
            <CAvatar name={user?.firstName} src={previewUrl} size={100} />

            <div className="d-flex flex-column justify-content-center">
              <span className="font-label fw-semibold">
                {[user?.firstName, user?.lastName].join(" ")}
              </span>
              <span className="font-description">{user?.email}</span>
            </div>
          </div>
        </CardBody>
        {!isEdit && (
          <CardFooter className="bg-brown border-0 ">
            {/* <div>
              <Button
                onClick={() => document.getElementById("fileInput").click()}
                className="btn-sm bg-primary border-0"
              >
                {"Upload Avatar"}
              </Button>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div> */}
            <div className="d-flex flex-row gap-2 justify-content-end">
              <Button
                className="btn-sm bg-primary border-0"
                onClick={() => setIsEdit("profile")}
              >
                Edit Profile
              </Button>
              <Button
                className="btn-sm bg-primary border-0"
                onClick={() => setIsEdit("password")}
              >
                Change Password
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
      {isEdit === "profile" && (
        <Card className={mobileView ? "w-100" : "w-50"}>
          <CardBody>
            <span className="font-title fw-semibold">Profile</span>
            <div className="d-flex flex-column mt-3">
              <span className="font-label mb-2">Avatar</span>
              <CInput
                type="file"
                name="file"
                accept="image/*"
                onChange={(e) => onHandleChange("file", e.target.files[0])}
                heightInput={"40px"}
              />
            </div>
            <div className="d-flex flex-column mt-3">
              <span className="font-label mb-2">Date of birth</span>
              <CInput
                type="date"
                name="dob"
                invalid={error?.dob}
                onChange={(e) => onHandleChange("dob", e.target.value)}
                heightInput={"40px"}
                value={data?.dob}
              />
              <FormFeedback invalid={error?.dob}>
                Date of birth is required
              </FormFeedback>
            </div>
            <div className="d-flex flex-column mt-2">
              <span className="font-label ">Gender</span>
              <Input
                id="gender"
                name="gender"
                type="select"
                invalid={error?.gender}
                value={data?.gender}
                onChange={(e) => onHandleChange("gender", e.target.value)}
                className="shadow-none"
              >
                <option value={""}>Select gender</option>
                <option value={"MALE"}>Male</option>
                <option value={"FEMALE"}>Female</option>
              </Input>
              <FormFeedback invalid={error?.gender}>
                Gender is required
              </FormFeedback>
            </div>
          </CardBody>
          <CardFooter className="bg-brown border-0 d-flex flex-row gap-2 justify-content-end">
            <Button
              className="btn-sm bg-danger border-0"
              onClick={() => setIsEdit(null)}
            >
              Cancel
            </Button>
            <Button
              className="btn-sm bg-primary border-0"
              onClick={loading ? null : onSubmit}
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
          </CardFooter>
        </Card>
      )}
      {isEdit === null && (
        <Card className={mobileView ? "w-100" : "w-50"}>
          <CardBody>
            <span className="font-title fw-semibold">Profile</span>
            <div className="d-flex flex-column mt-3">
              <span className="font-label ">Date of birth</span>
              <span className="font-label fw-semibold">
                {moment(user?.userProfiles?.dob).format("DD MMMM YYYY")}
              </span>
            </div>
            <div className="d-flex flex-column mt-2">
              <span className="font-label ">Gender</span>
              <span className="font-label fw-semibold">
                {getGender(user?.userProfiles?.gender)}
              </span>
            </div>
          </CardBody>
        </Card>
      )}

      {isEdit === "password" && (
        <Card className={mobileView ? "w-100" : "w-50"}>
          <CardBody>
            <span className="font-title fw-semibold">Change Password</span>
            <div className="d-flex flex-column my-3">
              <span className="font-label mb-2">Current Password</span>
              <CInput
                type="password"
                name="oldPassword"
                borderColor={errorPassword?.oldPassword && "border-danger"}
                placeholder="Current Password"
                invalid={errorPassword?.oldPassword}
                onChange={(e) =>
                  onHandleChangePassword("oldPassword", e.target.value)
                }
                heightInput={"40px"}
              />
            </div>

            <span className="font-label mb-2">New Password</span>
            <div className="border border-dark px-2 py-1 rounded my-2">
              <ul>
                <li>
                  <span style={{ fontSize: "14px" }}>
                    {t("reset_password_rule.one")}
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "14px" }}>
                    {t("reset_password_rule.two")}
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "14px" }}>
                    {t("reset_password_rule.three")}
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: "14px" }}>
                    {t("reset_password_rule.four")}
                  </span>
                </li>
              </ul>
            </div>
            <CInput
              type="password"
              name="password"
              borderColor={errorPassword?.password && "border-danger"}
              placeholder="New Password"
              invalid={errorPassword?.password}
              onChange={(e) =>
                onHandleChangePassword("password", e.target.value)
              }
              heightInput={"40px"}
            />
          </CardBody>
          <CardFooter className="bg-brown border-0 d-flex flex-row gap-2 justify-content-end">
            <Button
              className="btn-sm bg-danger border-0"
              onClick={() => setIsEdit(null)}
            >
              Cancel
            </Button>
            <Button
              className="btn-sm bg-primary border-0"
              onClick={loadingChange ? null : onSubmitChangePassword}
            >
              {loadingChange ? <Spinner size="sm" /> : "Save"}
            </Button>
          </CardFooter>
        </Card>
      )}
    </div>
  );
};

export default Profile;
