import { gql } from "@apollo/client";

const RegisterManual = gql`
  mutation Register($email: String!, $password: String!) {
    registration(email: $email, password: $password) {
      message
      status
      error
    }
  }
`;

export default RegisterManual;
