import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../contexts/GlobalContext";

const SectionChartDivHistory = ({ series, categories }) => {
  const { state } = useContext(GlobalContext);
  const { darkMode } = state;
  const [options, setOptions] = useState({
    chart: {
      zoomType: "xy",
      height: 250,
      backgroundColor: darkMode ? "#212531" : "#fff",
    },
    title: {
      text: ``,
    },
    xAxis: {
      categories: [],

      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "10px",
          color: darkMode ? "#fff" : "#000",
        },
      },
    },
    yAxis: [
      {
        labels: {
          format: "{value}%",
        },
        title: {
          text: "",
        },
      },
      {
        labels: {
          format: "{value}",
        },
        title: {
          text: "",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,

      valueSuffix: "%",
      valueDecimals: 2,
    },
    series: [],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
      itemWidth: 150,
      itemHeight: 20,
      itemMarginBottom: 5,
      itemStyle: {
        color: darkMode ? "#fff" : "#000",
      },
    },
  });

  useEffect(() => {
    setOptions({
      ...options,
      chart: {
        ...options.chart,
        backgroundColor: darkMode ? "#212531" : "#fff",
      },
      xAxis: {
        ...options.xAxis,
        categories: categories,
        labels: {
          ...options.xAxis.labels,
          style: {
            ...options.xAxis.labels.style,
            color: darkMode ? "#fff" : "#000",
          },
        },
      },
      yAxis: [
        {
          ...options.yAxis[0],
          labels: {
            ...options.yAxis[0].labels,
            style: {
              ...options.yAxis[0].labels.style,
              color: darkMode ? "#fff" : "#000",
            },
          },
        },
        {
          ...options.yAxis[1],
          labels: {
            ...options.yAxis[1].labels,
            style: {
              ...options.yAxis[1].labels.style,
              color: darkMode ? "#fff" : "#000",
            },
          },
          opposite: true,
        },
      ],
      legend: {
        ...options.legend,
        itemStyle: {
          ...options.legend.itemStyle,
          color: darkMode ? "#fff" : "#000",
        },
      },
      series: series,
    });
    // eslint-disable-next-line
  }, [series, categories, darkMode]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SectionChartDivHistory;
