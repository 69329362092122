import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { BsArrowRightCircle } from "react-icons/bs";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaSearch,
} from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RiExternalLinkLine } from "react-icons/ri";
import { TiLink } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import CInput from "../../../../components/CInput";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import UpdateProfile from "../../../../graphQL/Mutation/UpdateProfile";
import {
  isCurrency,
  isCurrencyWithOutDecimal,
  isCurrencyWithOutRpAndWithOutFormat,
} from "../../../../utils/isCurrency";
import { isFloat } from "../../../../utils/isFloat";
import IsMobile from "../../../../utils/IsMobile";
import TabRatio from "../field/ratio.json";
import userRef from "../field/userRef.json";

const SectionSummary = ({
  companiesDetail,
  darkMode,
  t,
  aboutCompany,
  keyPointsCompany,
  isMinus,
  setIsOpenMore,
  isOpenMore,
}) => {
  const navigate = useNavigate();
  const mobileView = IsMobile();
  const [search, setSearch] = useState("");
  const alert = useAlert();
  const { parameters } = useContext(GlobalContext);
  const [tokenLogin] = useState(secureLocalStorage.getItem("token"));
  const [parametersTemp, setParametersTemp] = useState(parameters);
  const [dashboard_arr, setDashboardArr] = useState(
    secureLocalStorage.getItem("dashboard_arr")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_arr"))
      : userRef
  );
  const [dashboard_obj] = useState(
    secureLocalStorage.getItem("dashboard_obj")
      ? JSON.parse(secureLocalStorage.getItem("dashboard_obj"))
      : null
  );

  const [updateRatio] = useMutation(UpdateProfile, {
    context: {
      headers: {
        Authorization: `Bearer ${secureLocalStorage.getItem("token")}`,
      },
    },
    onCompleted: () => {
      // setUserRatio(dashboard_arr);
      secureLocalStorage.setItem(
        "dashboard_arr",
        JSON.stringify(dashboard_arr)
      );
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onAddSummary = (item) => {
    setSearch("");
    if (!tokenLogin) {
      alert.info(t("screen.pleas_login_first"));
      secureLocalStorage.setItem(
        "pageDirection",
        `/company/${companiesDetail?.code}`
      );
      navigate("/login");
    }
    const new_dashboard_array = [...dashboard_arr];
    if (!new_dashboard_array.some((summary) => summary.value === item.value)) {
      new_dashboard_array.push(item);
      setDashboardArr(new_dashboard_array);
      let newDashboard = {
        ...dashboard_obj,
      };
      newDashboard[item.section] = {
        ...newDashboard[item.section],
        [item.value]: true,
      };
      secureLocalStorage.setItem("dashboard_obj", JSON.stringify(newDashboard));
      updateRatio({
        variables: {
          dashboard: newDashboard,
        },
      });
    } else {
      alert.info(t("screen.parameter_exists"));
    }
  };

  const onSearch = (value) => {
    setSearch(value);
    const newParameters = parameters.filter((parameter) =>
      parameter.value_display_name.toLowerCase().includes(value.toLowerCase())
    );
    setParametersTemp(newParameters);
  };

  const onEditRatio = () => {
    if (!tokenLogin) {
      alert.info(t("screen.pleas_login_first"));
      secureLocalStorage.setItem(
        "pageDirection",
        `/manage-ratio/${companiesDetail?.code}`
      );
      navigate("/login");
    } else {
      navigate(`/manage-ratio/${companiesDetail?.code}`);
    }
  };

  const UnitSummary = ({ item, companiesDetail }) => {
    switch (item?.unit) {
      case "currency":
        if (
          item?.origin?.value?.includes("price") ||
          item?.origin?.value?.includes("Price")
        ) {
          return isCurrencyWithOutDecimal(
            companiesDetail?.stockSummaries?.length > 0
              ? isFloat(
                  companiesDetail?.stockSummaries[0]?.[item?.origin?.value]
                ) || 0
              : 0
          );
        } else {
          return isCurrency(
            companiesDetail?.stockSummaries?.length > 0
              ? companiesDetail?.stockSummaries[0]?.[item?.origin?.value] || 0
              : 0
          );
        }

      case "percentage":
        return companiesDetail?.stockSummaries?.length > 0
          ? isFloat(
              companiesDetail?.stockSummaries[0]?.[item?.origin?.value] || 0
            ) + " %"
          : 0 + " %";

      case "boolean":
        return companiesDetail?.stockSummaries?.length > 0
          ? companiesDetail?.stockSummaries[0]?.[item?.origin?.value]
            ? "Yes"
            : "No"
          : "";

      case "number":
        return isCurrencyWithOutRpAndWithOutFormat(
          companiesDetail?.stockSummaries?.length > 0
            ? companiesDetail?.stockSummaries[0]?.[item?.origin?.value]
            : 0
        );

      case "date":
        return companiesDetail?.stockSummaries?.length > 0
          ? moment(
              companiesDetail?.stockSummaries[0]?.[item?.origin?.value]
            ).format("DD MMM YYYY")
          : "";

      default:
        return isCurrencyWithOutRpAndWithOutFormat(
          companiesDetail?.stockSummaries?.length > 0
            ? companiesDetail?.stockSummaries[0]?.[item?.origin?.value]
            : 0
        );
    }
  };
  const TruncateText = ({ text }) => {
    return (
      <div>
        {text ? (
          <div>
            <p
              className="font-description"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 4,
              }}
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />

            <div
              className="d-flex justify-content-center"
              style={{
                marginTop: "-10px",
                cursor: "pointer",
              }}
              onClick={() => setIsOpenMore(!isOpenMore)}
            >
              <IoIosArrowDown width={16} height={14} />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const CardAbout = () => {
    return (
      <Row className="mt-4">
        <Col sm="6">
          <Card body className="border-0  p-0">
            <CardTitle className="fw-bold font-title">
              {t("detail.about")}
            </CardTitle>
            <div>
              {aboutCompany ? (
                <TruncateText text={aboutCompany} />
              ) : (
                <span className="font-label">{t("detail.no_data")}</span>
              )}
            </div>
          </Card>
        </Col>
        <Col sm="6">
          <Card body className="border-0  p-0">
            <CardTitle className="fw-bold font-title">
              {t("detail.key_point")}
            </CardTitle>
            <div>
              {keyPointsCompany ? (
                <TruncateText text={keyPointsCompany} />
              ) : (
                <span className="font-label">{t("detail.no_data")}</span>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    );
  };

  const onAddRatio = (section) => {
    if (!tokenLogin) {
      alert.info(t("screen.pleas_login_first"));
      secureLocalStorage.setItem(
        "pageDirection",
        `/manage-ratio/${companiesDetail?.code}`
      );
      navigate("/login");
    } else {
      navigate(`/manage-ratio/${companiesDetail?.code}`, {
        state: {
          section: section,
        },
      });
    }
  };

  const CardSummarySection = ({ section, dashboardUser, companiesDetail }) => {
    const dashboardBySection = section.reduce((acc, curr) => {
      const filtered = dashboardUser.filter(
        (item) => item?.section === curr.slug
      );
      if (filtered.length > 0) {
        acc.push({ ...curr, data: filtered });
      }
      return acc;
    }, []);

    return (
      <Row className="mt-4">
        {dashboardBySection?.map((item_, index_) => (
          <Col sm="4" key={index_} className="mb-3">
            <Card className={`${darkMode && "border-primary"} h-100`}>
              <CardBody>
                <CardTitle className="fw-bold font-label">
                  {item_?.label}
                </CardTitle>
                {item_?.data?.map((item, index) => (
                  <Row key={index} className="border-bottom py-1">
                    <Col sm="8">
                      <span
                        className={`font-label ${
                          darkMode ? "text-light" : "text-dark"
                        }`}
                      >
                        {t("summary." + item.value, {
                          defaultValue:
                            item.value_display_name || item.valueDisplayName,
                        })}
                      </span>
                    </Col>
                    <Col sm="4">
                      <span className="font-label fw-semibold">
                        <UnitSummary
                          item={item}
                          companiesDetail={companiesDetail}
                        />
                      </span>
                    </Col>
                  </Row>
                ))}
              </CardBody>
              <CardFooter
                style={{ cursor: "pointer" }}
                className="border-0 bg-light d-flex flex-row justify-content-end"
                onClick={() => onAddRatio(item_)}
              >
                <span className="text-primary font-label  me-2">Add ratio</span>
                <BsArrowRightCircle size={16} color="#0078ff" />
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Card className="shadow-sm">
      <CardBody>
        <div className="d-flex flex-lg-row flex-sm-column flex-column  justify-content-between">
          {/* Button export and Follow */}
          {/* <div className="col-sm-12 col-lg-4 col-md-12">
            <div className="d-flex flew-row align-items-center gap-3 justify-content-end">
              <button className="btn btn-outline-primary rounded text-primary">
                <FaDownload size={14} />{" "}
                {!mobileView && t("detail.export_excel")}
              </button>
              <button className="btn btn-primary rounded text-light">
                <FaPlus size={14} /> {t("detail.follow")}
              </button>
            </div>
          </div> */}
          {/* Summary Company */}
          <div className="col-sm-12 col-lg-8 col-md-12">
            <div className={`d-flex flew-row align-items-center gap-3`}>
              <div className="d-lg-block d-none">
                <span className="fw-bold font-extra-large">
                  {companiesDetail?.name}
                </span>
              </div>
              <div>
                <div className="d-flex flex-row gap-1 align-items-center">
                  <div>
                    <span className="font-label fw-semibold">
                      {moment().format("HH:mm") >= "16:00"
                        ? isCurrencyWithOutDecimal(
                            companiesDetail?.stockSummaries?.length > 0
                              ? isFloat(
                                  companiesDetail?.stockSummaries[0]
                                    ?.closingPrice
                                ) || 0
                              : 0
                          )
                        : isCurrencyWithOutDecimal(
                            companiesDetail?.stockSummaries?.length > 0
                              ? isFloat(
                                  companiesDetail?.stockSummaries[0]?.price
                                ) || 0
                              : 0
                          )}
                    </span>
                  </div>
                  <div>
                    {isMinus(
                      companiesDetail?.stockSummaries?.length > 0
                        ? companiesDetail?.stockSummaries[0]?.change
                        : 0
                    ) ? (
                      <FaArrowAltCircleDown size={10} color="red" />
                    ) : (
                      <FaArrowAltCircleUp size={10} color="green" />
                    )}{" "}
                    <span
                      className={`font-description ${
                        isMinus(
                          companiesDetail?.stockSummaries?.length > 0
                            ? companiesDetail?.stockSummaries[0]?.change
                            : 0
                        )
                          ? "text-danger"
                          : "text-success"
                      }`}
                    >
                      {companiesDetail?.stockSummaries?.length > 0
                        ? companiesDetail?.stockSummaries[0]?.change
                        : 0}
                      {"%"}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "-5px",
                  }}
                >
                  <span className="text-secondary font-small py-0 my-0">
                    {companiesDetail?.stockSummaries?.length > 0
                      ? moment(
                          companiesDetail?.stockSummaries[0]?.dateCreated
                        ).format("DD MMM YYYY")
                      : ""}{" "}
                    {moment().format("HH:mm") >= "16:00"
                      ? `- ${t("detail.close_price")}`
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row gap-5 align-items-center">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    companiesDetail?.website?.includes("http")
                      ? companiesDetail?.website
                      : "https://" + companiesDetail?.website
                  }
                  className="font-label fw-semibold text-primary text-decoration-none"
                >
                  <TiLink size={16} />
                  {mobileView ? "website" : companiesDetail?.website}
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    companiesDetail?.website?.includes("http")
                      ? companiesDetail?.website
                      : "https://" + companiesDetail?.website
                  }
                  className="font-label fw-semibold text-secondary text-decoration-none"
                >
                  <RiExternalLinkLine size={16} />
                  {companiesDetail?.code}
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-lg-end align-items-sm-start align-items gap-1">
            <CInput
              value={search}
              leftIcon={<FaSearch size={14} />}
              widthInput={mobileView ? "100%" : "400px"}
              heightInput={"40px"}
              placeholder={t("detail.add_ratio_table")}
              borderColor={"border-primary"}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />

            <div style={{ cursor: "pointer" }} onClick={onEditRatio}>
              <span className="text-primary font-label fw-semibold me-2">
                Edit your ratio
              </span>
              <BsArrowRightCircle size={20} color="#0078ff" />
            </div>
          </div>
        </div>
        <div className="d-flex  flex-column">
          <div className="mb-2">
            {parametersTemp?.length > 0 && search?.length > 0 && (
              <div className="d-flex  justify-content-lg-end justify-content-sm-start justify-content-start gap-1">
                <div
                  className={`position-absolute  p-2 border  ${
                    darkMode ? "bg-secondary text-light" : "bg-light"
                  } mt-1 rounded shadow-sm z-1`}
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    width: mobileView ? "95%" : "400px",
                  }}
                >
                  {parametersTemp
                    ?.filter((item) => item?.origin?.key)
                    .map(
                      (item, index) =>
                        item?.origin?.key && (
                          <div
                            onClick={() => onAddSummary(item)}
                            className="p-2  card-search border-bottom d-flex flex-row justify-content-between align-items-center"
                            style={{
                              fontSize: mobileView ? "10px" : "14px",
                              cursor: "pointer",
                            }}
                          >
                            <div className="d-flex flex-column">
                              <span className="me-3 font-label">
                                {t("summary." + item.value, {
                                  defaultValue: item?.value_display_name,
                                })}
                              </span>

                              <span className="font-description text-secondary">
                                {"(" + item?.value_display_name + ")"}
                              </span>
                            </div>
                            <div>
                              <Badge color="primary">
                                {item?.section_display_name}
                              </Badge>
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>
            )}

            <CardSummarySection
              section={TabRatio}
              dashboardUser={dashboard_arr}
              companiesDetail={companiesDetail}
              search={search}
              onSearch={onSearch}
            />
          </div>
          <CardAbout companiesDetail={companiesDetail} />{" "}
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionSummary;
